import React from 'react';

export default function Card({ title, children }) {
  return (
    <div className="card my-4">
      {title ? <h6 className="card-header">{title}</h6> : null}
      <div className="card-body">
        {children}
      </div>
    </div>
  );
}
