import moment from 'moment';
import React from 'react';
import Flatpickr from 'react-flatpickr';
import { Link } from 'react-router-dom';
import { useCoreContext } from '../../../../core/context';
import { ACTIVITIES } from '../../../../core/experience';
import { useFoodKinds } from '../../../../core/hooks/data';
import Card from '../../../App/Card';
import { FoodKindFilters, MobilityFilters, TextFilters } from './TagFilters';

function Filters({ filters, change }) {
  const { mobilities } = useCoreContext();
  const foodKinds = useFoodKinds()

  return (
    <div className="excursions-filters">
      <div className="card card-voucher my-4">
        <div className="card-body">
          <Link to="/gifts/vouchers" className="voucher-link">
            <div className="text">
              <h4>Bon Cadeau</h4>
              <span>Régalez vos amis ou votre famille avec un chèque-cadeau</span>
            </div>
            <div className="icon">
              <i className="fa fa-gift"></i>
            </div>
          </Link>
        </div>
      </div>
      <h5 className="hide">Filtres</h5>
      <Card title="Date">
        <div className="row">
          <div className="col-6">
            <label htmlFor="inputFrom">De</label>
            <Flatpickr className="form-control" id="inputFrom" value={filters.startDate} options={{ minDate: moment().startOf('day').format('YYYY-MM-DD'), onChange: (v) => change('startDate', v[0]) }} />
          </div>
          <div className="col-6">
            <label htmlFor="inputTo">A</label>
            <Flatpickr className="form-control" id="inputTo" value={filters.endDate} options={{ minDate: filters.startDate, onChange: (v) => change('endDate', v[0]) }} />
          </div>
        </div>
      </Card>

      <Card title="Voyageurs">
        <select className="custom-select" defaultValue={filters.travelersCount} onChange={(e) => change('travelersCount', e.target.value)}>
          {Array(8).fill().map((_, i) => <option key={i} value={i + 1}>{i + 1}</option>)}
        </select>
      </Card>

      <Card title="Activités">
        <TextFilters options={ACTIVITIES} selected={filters.selectedActivities} change={(v) => change('selectedActivities', v)} />
      </Card>

      <Card title="Restauration">
        <FoodKindFilters options={foodKinds} selected={filters.selectedFoods} change={(v) => change('selectedFoods', v)} />
      </Card>

      <Card title="Mobilité">
        <MobilityFilters options={mobilities} selected={filters.selectedMobilities} change={(v) => change('selectedMobilities', v)} />
      </Card>
    </div>
  );
}

export default Filters;
