import React from 'react';
import delve from 'dlv';
import useTravelerMenu from '../../../../core/hooks/menu';

export default function Menu({ experience, traveler, change }) {
  const { menus, currentMenu, setMenuId } = useTravelerMenu({ experience, traveler, change });

  if (!menus.length) { return null; }
  let content;
  if (menus.length === 1) {
    content = <div className="traveler-menu">{delve(currentMenu, 'name')}</div>;
  } else {
    content = (
      <select className="traveler-menu" name="" defaultValue={traveler.menuIds[0]} onChange={(e) => setMenuId(e.target.value)} >
        {menus.map(menu => <option key={menu.id} value={menu.id}>{menu.name}</option>)}
      </select>
    );
  }
  return (
    <div className="col-md-4">
      <div className="form-group">
        <label htmlFor="">Plat</label>
        {content}
      </div>
    </div>
  );
}
