import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

function ScrollToTopOnMount() {
  useEffect(() => { window.scrollTo(0, 0); }, []);
  return null;
}

function Page({ title, children }) {
  return (
    <>
      <ScrollToTopOnMount />
      <Helmet>
        <title>MBC {title ? ` - ${title}` : ''}</title>
      </Helmet>
      {children}
    </>
  );
}

export default Page;
