import React, { useCallback, useMemo } from 'react';
import update from 'react-addons-update';
import Trips from '../../App/Trips';
import Menus from './Menus';

export default function Experience({ experience, excursion, onExcursionUpdated }) {
  const trips = useMemo(() => excursion?.trips?.filter(trip => experience?.tripIds?.includes(trip.id)) || [], [excursion, experience]);
  const updateTrip = useCallback((trip) => {
    const index = excursion.trips.findIndex(({ id }) => id === trip.id);
    onExcursionUpdated(update(excursion, {
      trips: { [index]: { $set: { ...excursion.trips[index], ...trip } } },
    }));
  }, [excursion, onExcursionUpdated]);

  if (!experience) { return null; }

  return (
    <div className="card mb-4 shadow-sm experience-item">
      <div className="card-body">
        <h4 className="mt-2 mb-4">{experience.name}</h4>
        <p className="mb-4" dangerouslySetInnerHTML={{ __html: experience.description }} />
        <Menus experience={experience} />
        <Trips trips={trips} excursion={excursion} hideTitle={true} subtitle="Dates Disponibles" onTripUpdated={updateTrip} />
      </div>
    </div>
  );
}
