import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useGiftVoucherCheck } from '../../../core/hooks/gifts/vouchers';
import { travelersCount } from '../../../core/transaction';
import { availableSeats } from '../../../core/trip';
import Booking from '../../Booking';

export default function TripBooking({ transaction, excursion, experience, trip, travelers, onGiftVoucherChange }) {
  const [tncAccepted, setTncAccepted] = useState(false);
  const [checkoutDisabled, checkoutError] = useMemo(() => {
    if (availableSeats(trip) < travelersCount(travelers)) {
      return [true, 'Pas assez de places disponibles pour votre réservation.'];
    }
    return [!tncAccepted || travelers.length <= 0];
  }, [tncAccepted, travelers, trip]);

  const [remarks, setRemarks] = useState('');
  const checkoutState = { transaction, experience, trip, travelers, excursion, remarks };
  const checkoutPath = useMemo(() => `/excursions/${excursion.id}/trips/${trip.id}/checkout`, [excursion, trip]);

  const { giftVoucher, checkGiftVoucher } = useGiftVoucherCheck();

  useEffect(() => {
    if (!giftVoucher || giftVoucher?.invalid) { onGiftVoucherChange(null); }
    if (giftVoucher?.loading) { return; }

    onGiftVoucherChange(giftVoucher);
  }, [giftVoucher, onGiftVoucherChange]);

  return (
    <div className="col-md-4 order-md-2 mb-4">
      <h5 className="d-flex justify-content-between align-items-center mb-3">
        <span className="">Résumé</span>
        <span className="badge badge-danger badge-pill">{travelers.length}</span>
      </h5>
      <Booking transaction={transaction} experience={experience} trip={trip} >
        <div className="form-row mb-3">
          <div className="col-md-12">
            <div className="form-group m-0">
              <label htmlFor="text-area-remarks">Remarques</label>
              <textarea id="text-area-remarks" className="form-control" name="name" rows="3" onChange={(e) => setRemarks(e.target.value)} value={remarks} />
            </div>
          </div>
        </div>
        <div className="form-row mb-3">
          <div className="col-md-12">
            <div className="form-group m-0">
              <label htmlFor="">Code bon cadeau</label>
              <input
                id="voucher-code"
                type="text"
                className={`form-control ${giftVoucher?.invalid ? 'is-invalid' : ''} ${giftVoucher?.availableCents ? 'is-valid' : ''}`}
                name="promo"
                onBlur={(e) => checkGiftVoucher(e.target.value)}
              />
              {giftVoucher?.loading && <div className="invalid-feedback text-muted" style={{ display: 'block' }}>Vérification en cours...</div>}
            </div>
          </div>
        </div>
        <div className="cgv-block">
          <p>Avant de continuer, merci de lire les <a href="/cgv/" target="_blank">Conditions Générales de Vente</a> et de les accepter en cliquant ci-dessous</p>
          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input id="cgv" name="paymentMethod" type="checkbox" className="custom-control-input" required="" onChange={(e) => setTncAccepted(e.target.checked)} />
              <label className="custom-control-label" htmlFor="cgv">J'accepte les <b>CGV</b> et j'ai pris connaissances des différentes informations concernant le voyage en train rétro</label>
            </div>
          </div>
        </div>
        <Link to={{ pathname: checkoutPath, state: checkoutState }} className={`btn btn-primary btn-lg btn-block ${checkoutDisabled ? 'disabled' : ''}`}>Confirmer</Link>
        {checkoutError ? (
          <div className="form-group">
            <input type="hidden" className="form-control is-invalid" />
            <div className="invalid-feedback">
              {checkoutError}
            </div>
          </div>
        ) : null}
      </Booking>
    </div>
  );
}
