import React, { useContext, createContext } from 'react';
import { useMobilities } from './hooks/data';

const coreContext = createContext();

export function CoreProvider({ children }) {
  const core = useCoreProvider();
  return <coreContext.Provider value={core}>{children}</coreContext.Provider>;
}

export function MockCoreProvider({ children, value }) {
  return <coreContext.Provider value={value}>{children}</coreContext.Provider>;
}

export function useCoreContext() {
  return useContext(coreContext);
}

function useCoreProvider() {
  const mobilities = useMobilities()
  return { mobilities };
}
