import React from 'react';
import update from 'react-addons-update';
import FieldBoundTextField from '../../../admin/components/App/Form/FieldBoundTextField';
import { CANTONS } from '../../../core/booking';

export default function ClientForm({ client, onChange }) {
  return (
    <>
      <div className="row">
        <div className="col-md-6 mb-3">
          <FieldBoundTextField field="firstName" label="Prénom" value={client} change={onChange} />
        </div>
        <div className="col-md-6 mb-3">
          <FieldBoundTextField field="lastName" label="Nom" value={client} change={onChange} />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mb-3">
          <FieldBoundTextField field="phone" label="Phone" value={client} change={onChange} type="tel" />
        </div>
        <div className="col-md-6 mb-3">
          <FieldBoundTextField field="email" label="Email" value={client} change={onChange} type="email" />
        </div>

      </div>

      <div className="mb-3">
        <FieldBoundTextField field="address" label="Adresse" value={client} change={onChange} />
      </div>


      <div className="row">
        <div className="col-md-4 mb-3">
          <label htmlFor="state">Canton</label>
          <select className="custom-select d-block w-100" id="state" required={true} value={client.canton} onChange={e => onChange(update(client, { canton: { $set: e.target.value } }))}>
            {[['', 'Choisir Canton…'], ...Object.entries(CANTONS)].map(([code, text]) => <option key={code} value={code}>{text}</option>)}
          </select>
          <div className="invalid-feedback">
            Merci de sélectionner un Canton..
          </div>
        </div>
        <div className="col-md-5 mb-3">
          <FieldBoundTextField field="city" label="Ville" value={client} change={onChange} />
        </div>
        <div className="col-md-3 mb-3">
          <FieldBoundTextField field="zipCode" label="NPA" value={client} change={onChange} />
        </div>
      </div>
    </>
  );
}
