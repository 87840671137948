import React from 'react';
import update from 'react-addons-update';

export default function FieldBoundTextField({ id, field, label, value, change, type, wrapInsideFormGroup, className, optional }) {
  const content = (
    <>
      <label htmlFor={field}>{label}</label>
      <input type={type || 'text'} className="form-control" id={id || field} placeholder="" value={value[field] || ''} required={!optional} onChange={e => change(update(value, { [field]: { $set: e.target.value } }))} />
      <div className="invalid-feedback">
      </div>
    </>
  );
  if (!wrapInsideFormGroup) { return content; }
  return (
    <div className={`form-group ${className}`}>
      {content}
    </div>
  );
}

FieldBoundTextField.defaultProps = {
  wrapInsideFormGroup: false,
  className: '',
  optional: false,
};
