import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import Page from '../Page';
import Failure from './Failure';
import RefreshGiftVoucher from './RefreshGiftVoucher';
import Success from './Success';

export default function GiftVoucherStatusPage({ location, match, history }) {
  const uuid = queryString.parse(location.search).uuid;
  const [status, setStatus] = useState(match.params.status === 'failure' ? 'FAILED' : 'loading');
  const [id, setId] = useState(null);

  useEffect(() => {
    if (!uuid) { return; }

    fetch(`/api/gifts/vouchers/${uuid}`)
      .then((res) => res.json())
      .then(({ giftVoucher }) => {
        setId(giftVoucher.id);

        const { transaction: { walleeState: state } } = giftVoucher;
        setStatus(state);
      })
      .catch((err) => {
        setStatus('FAILED');
        console.error(err)
      });
  }, [uuid]);

  useEffect(() => {
    if (status !== 'FAILED') { return; }
    // history.replace({ pathname: '/gifts/vouchers/failure', search: location.search })
  }, [status, history, location.search]);

  let content;
  switch(status) {
    case 'FULFILL':
      content = <Success uuid={uuid} />;
      break;
    case 'FAILED':
      content = <Failure uuid={uuid} />;
      break;
    default:
      content = <RefreshGiftVoucher id={id} onCompleted={setStatus} />;
      break;
  }

  return (
    <Page title="GiftVoucher">
      <section className="py-5 page-success">
        <div className="container">
          {content}
        </div>
      </section>
    </Page>
  );
}
