import { useQuery } from '@apollo/react-hooks';
import delve from 'dlv';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import update from 'react-addons-update';
import Transaction from '../../../core/transaction';
import { DEFAULT_TRAVELER } from '../../../core/traveler';
import { formattedDate, tripExperience } from '../../../core/trip';
import ErrorMessage from '../../App/ErrorMessage';
import Loading from '../../App/Loading';
import Page from '../Page';
import Booking from './Booking';
import Travelers from './Travelers';

export const TRIP_QUERY = loader('../../App/trip.gql');
export const EXCURSION_QUERY = loader('../../../core/graphql/queries/excursion.gql');

function Icon({ accessory }) {
  return (
    <span className="ei-icon">
      <i className={accessory.icon}></i>
    </span>
  );
}

function Trip({ excursion, trip, isGift }) {
  const [giftVoucher, setGiftVoucher] = useState(null);
  const [travelers, setTravelers] = useState([DEFAULT_TRAVELER()]);
  if (!excursion || !trip) { return null; }

  const experience = tripExperience(trip, excursion);
  if (!experience) { return null; }

  return (
    <div className="row">
      <Booking
        transaction={new Transaction(experience, travelers, { giftVoucher })}
        excursion={excursion}
        experience={experience}
        trip={trip}
        travelers={travelers}
        isGift={isGift}
        onGiftVoucherChange={setGiftVoucher}
      />
      <div className="col-md-8 order-md-1">
        <h4 className="mb-3">{excursion.name}</h4>
        <h5 className="mb-3">Réservations</h5>
        <div className="excursion-selected">
          <small>Votre Sélection</small>
          <div className="excursion-item">
            <div className="ei-title">
              <h4>{experience.name}</h4>
              <span>{formattedDate(trip)}</span>
            </div>
            <div className="ei-options">
              {trip.acceptedMobilities
                ?.map(accessory => <Icon key={accessory} accessory={accessory} />)}
            </div>
          </div>
        </div>

        <Travelers
          trip={trip}
          experience={experience}
          travelers={travelers}
          add={() => setTravelers(update(travelers, { $push: [DEFAULT_TRAVELER()] }))}
          remove={(i) => setTravelers(update(travelers, { $splice: [[i, 1]] }))}
          change={(t, i) => setTravelers(update(travelers, { $splice: [[i, 1, t]] }))}
        />
        <br />
        <p dangerouslySetInnerHTML={{ __html: excursion.description }} />
      </div>
    </div>
  );
}

function TripPage({ match, location }) {
  const isGift = location?.state?.isGift;
  const [trip, setTrip] = useState(delve(location, ['state', 'trip']));
  const [excursion, setExcursion] = useState(delve(location, ['state', 'excursion']));

  const { loading: tripLoading, error: tripError } = useQuery(TRIP_QUERY, {
    variables: { id: parseInt(match.params.id, 10) },
    onCompleted: (data) => { if (data && data.trip) { setTrip(data.trip); } }
  });
  const { loading: excursionLoading, error: excursionError } = useQuery(EXCURSION_QUERY, {
    variables: { id: parseInt(match.params.excursionId, 10) },
    onCompleted: (data) => { if (data && data.excursion) { setExcursion(data.excursion); } }
  });
  const loading = tripLoading || excursionLoading;
  const error = tripError || excursionError;
  return (
    <Page title={excursion && excursion.name}>
      <section className="py-5">
        <div className="container">
          <Loading loading={loading} />
          <ErrorMessage message="Erreur lors du chargement des données" active={!!error} />
          {!loading && <Trip excursion={excursion} trip={trip} isGift={isGift} />}
        </div>
      </section>
    </Page>
  );
}

export default TripPage;
