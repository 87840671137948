import React from 'react';

export default function Failure() {
  return (
    <div className="failure-block">
      <div className="failure-msg">
        <i className="fa fa-exclamation-triangle faa-vertical animated"></i>
        <h5>Une erreur est survenue avec votre paiement, votre commande a été annulée.</h5>
      </div>
    </div>
  );
}
