import React from 'react';
import Excursion from './Excursion';
import { filterTrips } from '../../../../core/trip';
import { filterExperiences } from '../../../../core/experience';
import { maxBy, orderBy } from '../../../../core/utils';

function filter(excursions, filters) {
  let filtered = filterExperiences(excursions, filters);
  return filtered.filter(e => !e.trips || filterTrips(e.trips, { ...filters, skipAvailabilityFilter: true, skipTimeFilter: true }).length);
}

export default function Excursions({ excursions, filters, onExcursionUpdated, title }) {
  if (!excursions) { return null; }

  const filtered = filter(excursions, filters);
  const maxOrder = maxBy(excursions, e => e.order)?.order ?? 0;
  const ordered = orderBy(filtered, (exc) => {
    if (!exc.trips) { return exc.order; }
    return filterTrips(exc.trips, filters).length > 0 ? exc.order : maxOrder + exc.order;
  });

  return (
    <div className="excursions-list">
      <h5 className={title ? '' : 'hide'}>{title ?? 'Excursions'}</h5>
      <div className="row my-4">
        {
          ordered.map((excursion) => (
            <Excursion
              key={excursion.id}
              excursion={excursion}
              filters={filters}
              onExcursionUpdated={onExcursionUpdated}
            />))
        }
      </div>
    </div>
  );
}
