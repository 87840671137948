import delve from 'dlv';
import { filterByInclusion } from './utils';

export function filterExperiences(excursions, filters) {
  let filtered = excursions;
  if (delve(filters, ['selectedActivities', 'length'])) {
    const activities = filters.selectedActivities.map(a => a.toUpperCase());
    filtered = filterByInclusion(
      filtered,
      e => e.experiences ? e.experiences.flatMap(e => e.userTypes) : activities,
      activities,
    );
  }
  if (delve(filters, ['selectedFoods', 'length'])) {
    const ids = filters.selectedFoods;
    filtered = filterByInclusion(
      filtered,
      e => e.experiences ? e.experiences.flatMap(e => e.menus).flatMap(m => m.foodKinds.map(k => k.id)) : ids,
      ids,
    );
  }
  return filtered;
}

export const ACTIVITIES = {
  family: 'Famille',
  friends: 'Amis',
  after_work: 'After Work'
};
