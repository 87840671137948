import React from 'react';

function Image({ path, title }) {
  return (
    <div className="jumbotron static-header" style={{ backgroundImage: `url(${path})`}}>
      <div className="sh-container d-flex justify-content-center align-items-center">
        <h1 className="text-center">{title}</h1>
      </div>
    </div>
  );
}

export default Image;
