import React from 'react';
import update from 'react-addons-update';

function updateAndChange(event, traveler, change) {
  change(
    update(
      traveler, {
        count: {
          $set: parseInt(event.target.value, 10),
        },
      },
    ),
  );
}

export default function Count({ traveler, change }) {
  return (
    <div className="col-md-4">
      <div className="form-group">
        <label htmlFor={`traveler-${traveler._id}-count`}>Nombre de Participants</label>
        <select id={`traveler-${traveler._id}-count`} className="traveler-count" name="" value={traveler.count} onChange={e => updateAndChange(e, traveler, change)}>
          {Array(4).fill(0).map((_, i) => <option key={i + 1} value={i + 1}>{i + 1}</option>)}
        </select>
      </div>
    </div>
  );
}
