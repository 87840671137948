import { useCallback, useState } from 'react';

export function useGiftVoucherCheck() {
  const [giftVoucher, setGiftVoucher] = useState(null);
  const checkGiftVoucher = useCallback((code) => {
    if (code === '') { return setGiftVoucher(null); }
    if (giftVoucher?.code === code) { return; }

    setGiftVoucher({ code, loading: true });
    fetch(`/api/gifts/vouchers/${code}/check`).then(async (res) => {
      try {
        if (!res.ok) {
          return setGiftVoucher((current) => (current?.code === code ? { ...current, invalid: true } : current));
        }

        const { id, availableCents } = await res.json();
        setGiftVoucher((current) => (current?.code === code ? { ...current, id, availableCents, invalid: availableCents === 0 } : current));
      } finally {
        setGiftVoucher((current) => (current?.code === code ? { ...current, loading: false } : current));
      }
    });
  }, [giftVoucher]);

  return { giftVoucher, checkGiftVoucher }
}
