import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import queryString from 'query-string';
import { loader } from 'graphql.macro';
import Success from './Success';
import Failure from './Failure';
import RefreshBooking from './RefreshBooking';
import Page from '../Page';

export const BOOKING_BY_UUID_QUERY = loader('./booking_by_uuid.gql');

export default function BookingStatusPage({ location, match, history }) {
  const uuid = queryString.parse(location.search).uuid;
  const [status, setStatus] = useState(match.params.status === 'failure' ? 'FAILED' : 'loading');
  const [getBookingByUUID, { data }] = useLazyQuery(BOOKING_BY_UUID_QUERY);

  useEffect(() => {
    if (!uuid) { return; }
    getBookingByUUID({ variables: { uuid } });
  }, [getBookingByUUID, uuid]);

  useEffect(() => {
    if (status !== 'FAILED') { return; }
    history.replace({ pathname: '/bookings/failure', search: location.search })
  }, [status, history, location.search]);

  let content;
  switch(status) {
    case 'FULFILL':
      content = <Success uuid={uuid} />;
      break;
    case 'FAILED':
      content = <Failure uuid={uuid} />;
      break;
    default:
      content = <RefreshBooking id={data && data.bookingByUUID && data.bookingByUUID.id} onCompleted={setStatus} />;
      break;
  }

  return (
    <Page title="Booking">
      <section className="py-5 page-success">
        <div className="container">
          {content}
        </div>
      </section>
    </Page>
  );
}
