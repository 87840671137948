import React from 'react';
import update from 'react-addons-update';
import Tooltip from '../../../App/Tooltip';

export default function Option({ option, traveler, change }) {
  const key = option.kind === 'menu' ? 'menuOptionIds' : 'experienceOptionIds';
  const active = traveler[key].includes(option.id);
  const toggle = () => {
    const operation = active ? { [key]: { $set: traveler[key].filter(a => a !== option.id) } }
      : { [key]: { $push: [option.id] } };
    change(update(traveler, operation));
  };
  return (
    <div className="control-icon-checkbox trip-traveler-option">
      <Tooltip title={option.name}>
        <label>
          <input className="real-toggle" type="checkbox" defaultChecked={active} onChange={toggle} />
          <div className="fake-toggle"><i className={option.icon}></i></div>
        </label>
      </Tooltip>
    </div>
  );
}
