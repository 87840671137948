import React from 'react';
import delve from 'dlv';
import { formatMoney } from '../../../admin/core/money';

export default function Menus({ experience }) {
  if (!delve(experience, ['menus', 'length'])) { return null; }
  return (
    <>
      <h6>Menus</h6>
      <ul className="list-group">
        {experience.menus.map((menu) => (
          <li key={menu.id} className="list-group-item d-flex align-items-center">
            <span className="menu-name">{menu.name}</span>
            <div className="menu-details">
              {menu.pdf && <a className="btn-pdf" href={menu.pdf} target="_blank" rel="noopener noreferrer"><i className="fa fa-file-pdf"></i></a>}
              <span className="badge badge-primary badge-pill">Dès {formatMoney(menu.transactionDetail.cents, 0, '')}.-</span>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
}
