import React, { useState } from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import delve from 'dlv';
import Loading from '../../App/Loading';
import ErrorMessage from '../../App/ErrorMessage';
import { tripById } from '../../../core/trip';
import Page from '../Page';
import Excursion from './Excursion';
import { yesterdayISO8601 } from '../../../admin/core/date';

export const QUERY = loader('./excursion.gql');

function ExcursionPage({ match, location }) {
  const isGift = location?.state?.isGift ?? false;
  const [excursion, setExcursion] = useState(delve(location, ['state', 'excursion']));
  const { loading, error } = useQuery(QUERY, {
    variables: {
      id: parseInt(match.params.id, 10),
      date: yesterdayISO8601(),
    },
    onCompleted: (data) => {
      if (data && data.excursion) {
        setExcursion({
          ...excursion, ...data.excursion,
          trips: data.excursion.trips.map(trip => ({ ...tripById(excursion, trip.id), ...trip })),
        });
      }
    },
  });
  return (
    <Page title={excursion && excursion.title}>
      <section className="py-5">
        <div className="container">
          <Loading loading={loading && !excursion} />
          <ErrorMessage message="Erreur lors du chargement des données" active={!!error} />
          <Excursion excursion={excursion} onExcursionUpdated={setExcursion} isGift={isGift} />
        </div>
      </section>
    </Page>
  );
}

export default ExcursionPage;
