import React from 'react';
import Accessory from './Accessory';

function Accessories({ trip, traveler, change }) {
  const accepted = trip.acceptedMobilities.filter(mobility => mobility?.travelerKinds?.includes(traveler.kind.toUpperCase()));
  if (!accepted.length) { return null; }

  return (
    <div className="col-md-8">
      <div className="form-group m-0">
        <label htmlFor="">Accessoire</label>
        <div className="control-icon-checkbox-group traveler-accessories">
          {
            accepted.map(({id, icon}) =>
              <Accessory key={id} id={id} icon={icon} traveler={traveler} change={change} />)
          }
        </div>
      </div>
    </div>
  );
}

export default Accessories;
