import React from 'react';
import { formatMoney } from '../../admin/core/money';

export default function BookingLine({ line }) {
  return (
    <li className="list-group-item d-flex justify-content-between lh-condensed" id={`booking-line-${line.contentId ?? line.id}-${line.total}`}>
      <div>
        <h6 className="my-0">{line.title}</h6>
        <small className="text-muted">{line.subtitle}</small>
      </div>
      <div>
        {line.pdfUrl && <a className="pdf-icon mx-2" href={line.pdfUrl} target="_blank" rel="noopener noreferrer"><i className="fa fa-file-pdf"></i></a>}
        <span className="text-muted">{formatMoney(line.total * 100, 2, '')}</span>
      </div>
    </li>
  );
}
