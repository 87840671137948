import React from 'react';
import Page from './Page';

export default function Enterprise() {
  return (
    <Page title="Enterprise">
      <section className="py-5">
        <div className="container">

          <h2 className="font-weight-light">Excepteur sint occaecat cupidatat non proident.</h2>
          <p className="lead">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

          <div className="py-5 font-weight-light">
            <h5>Direct Marketing</h5>
            <p>Subject to applicable laws and regulations, we may from time to time send direct marketing materials promoting services, products, facilities, or activities to you using information collected from you. If you no longer want to receive marketing-related communications from us, you may opt-out of such communications by clicking the “unsubscribe” link at the bottom of emails you receive from us. You may also opt-out by contacting us directly using the contact information in the “Contact Us” section below. We will endeavour to respond to your opt-out request promptly, but we ask that you please allow us reasonable time to process your request. We will not provide your information to third parties for direct marketing or other unrelated purposes without your written consent.</p>
            <p>Please note that if you opt-out from receiving marketing-related communications, we may still need to send you communications about your use of our products or services, or other matters, subject to applicable laws and regulations.</p>
            <div id="data-transfer"></div>
            <h5>Data Transfer</h5>
            <p>Your personal information may be stored and processed in any country, province or state (each a “<strong>Country</strong>”) where we have facilities or in which we engage third party service providers. As a result, your personal information may be transferred to Countries outside your Country of residence, which may have different data protection rules than in your Country. While such information is outside of your Country, it is subject to the laws of the Country in which it is held, and may be subject to disclosure to the governments, courts or law enforcement or regulatory agencies of such other Country, pursuant to the laws of such Country. However, our practices regarding your personal information will at all times continue to be governed by this Privacy Policy and, if applicable, we will comply with the GDPR requirements of providing adequate protection for the transfer of personal information from the EU/EEA to other countries.</p>
            <div id="third-party-websites-and-services"></div>
            <h5>Third Party Websites and Services</h5>
            <p>This Privacy Policy applies only to the Unsplash Services and does not extend to any websites or products or services provided by third parties. We do not assume responsibility for the privacy practices of such third parties, and we encourage you to review all third party privacy policies prior to using third party websites or products or services.</p>
            <div id="childrens-privacy"></div>
            <h5>Children's Privacy</h5>
            <p>The Unsplash Services are not directed to children under the age of 16, and we do not knowingly collect personal information from children under the age of 16 without obtaining parental consent. If you are under 16 years of age, then please do not use or access the Unsplash Services at any time or in any manner. If we learn that personal information has been collected on the Unsplash Services from persons under 16 years of age and without verifiable parental consent, then we will take the appropriate steps to delete this information. If you are a parent or guardian and discover that your child under 16 years of age has provided personal information, then you may alert us as set forth in the <a href="#contact-us">“Contact Us” section</a> and request that we delete that child’s personal information from our systems.</p>
            <div id="contact-us"></div>
            <h5>Contact Us</h5>
            <p>For any questions or comments about this Privacy Policy or your personal information, to make an access or correction request, to exercise any applicable rights, to make a complaint, or to obtain information about our policies and practices with respect to any service providers outside Canada, our Privacy Officer can be reached at <a href="mailto:privacy@unsplash.com">privacy@unsplash.com</a>.</p>
          </div>
        </div>
      </section>
    </Page>
  );
}
