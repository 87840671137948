import React from 'react';
import Card from './Card';

export default function Loading({ loading }) {
  if (!loading) { return null; }
  return (
    <div className="row">
      <div className="col-md-12 order-md-1">
        <Card>
          <div className="success-msg text-center"><i className="fa fa-spinner fa-spin fa-2x"></i></div>
          <br />
          <div className="success-msg text-center">Chargement des données…</div>
        </Card>
      </div>
    </div>
  );
}
