import React, { useState } from 'react';
import delve from 'dlv';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import Carousel from './Carousel';
import { minBy } from '../../core/utils';
import { filterTrips } from '../../core/trip';
import { yesterdayISO8601 } from '../../admin/core/date';
import { formatMoney } from '../../admin/core/money';

export const QUERY = loader('./queries/excursion_carousel.gql');

function tripsExperiences(excursion, trips) {
  return excursion.experiences.filter(({ id }) => trips.find(({ experience }) => experience && experience.id === id));
}

function badge(excursion, { tripId }) {
  if (!excursion || !excursion.trips || !excursion.experiences) { return null; }
  let trips = filterTrips(excursion.trips);
  if (tripId) {
    trips = trips.filter(({ id }) => id === tripId);
  }
  const experiences = tripsExperiences(excursion, trips);
  const menus = experiences.flatMap(e => e.menus);
  const adultMenus = menus.filter(m => m.travelerKind === 'ADULT');
  const cheapestMenu = minBy(adultMenus, m => delve(m, ['transactionDetail', 'cents']));
  if (!cheapestMenu || !delve(cheapestMenu, ['transactionDetail', 'cents'])) { return null; }
  return { title: 'A partir de', subtitle: `${formatMoney(cheapestMenu.transactionDetail.cents, 0, '')}.-` }
}

function Excursion({ match, location }) {
  const [excursion, setExcursion] = useState(delve(location, ['state', 'excursion']));
  useQuery(QUERY, {
    variables: { id: parseInt(match.params.id, 10), date: yesterdayISO8601() },
    onCompleted: (data) => { if (data && data.excursion) setExcursion(data.excursion); }
  });
  return <Carousel slides={delve(excursion, ['carousel', 'slides'])} badge={badge(excursion, match.params)} />;
}

export default Excursion;
