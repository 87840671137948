import React from 'react';
import { formatMoney } from '../../../../admin/core/money';
import Card from '../../../App/Card';

export default function Transaction({ transaction }) {
  return (
    <Card title="Total">
      <div className="row">
        <div className="col-md-4">
          <div className="form-group">
            <label>Prix</label>
            <h4 className="">{formatMoney(transaction.totalCents)}</h4>
          </div>
        </div>
      </div>
    </Card>
  );
}
