import React from 'react';
import delve from 'dlv';
import { availableExperienceOptions } from '../../../../core/traveler';
import Option from './Option';

export default function Options({ experience, menu, traveler, ...props }) {
  const options = [
    ...(delve(menu, 'options') || []).map(mo => ({ kind: 'menu', ...mo })),
    ...availableExperienceOptions(traveler, experience).map(eo => ({ kind: 'experience', ...eo })),
  ];
  if (!options.length) { return null; }
  return (
    <div className="col-md-4">
      <div className="form-group m-0">
        <label htmlFor="">Options</label>
        <div className="control-icon-checkbox-group traveler-menu-options">
          {options.map(option => <Option key={option.id} option={option} traveler={traveler} {...props} />)}
        </div>
      </div>
    </div>
  );
}
