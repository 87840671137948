import { useMutation } from '@apollo/react-hooks';
import delve from 'dlv';
import { loader } from 'graphql.macro';
import React, { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import update from 'react-addons-update';
import { Redirect } from 'react-router-dom';
import { pay } from '../../../core/booking';
import { giftRedemptionVariables } from '../../../core/gifts/redemption';
import Page from '../Page';
import StepOne from './StepOne';

export const MUTATION = loader('./booking.gql');
export const MUTATION_GIFT_REDEMPTION = loader('./giftRedemption.gql');

function redirectToTrip({ excursionId, tripId }) {
  return <Redirect to={`/excursions/${excursionId}/trips/${tripId}`} />;
}

function CheckoutPageContent({ match: { params }, location }) {
  const [createGiftRedemption, giftRedemptionResult] = useMutation(MUTATION_GIFT_REDEMPTION);
  const [createBooking, { loading, data, error }] = useMutation(MUTATION);
  const [transaction, setTransaction] = useState(location.state?.transaction);
  const giftRedemption = useMemo(() => giftRedemptionResult?.data?.createGiftRedemption?.giftRedemption, [giftRedemptionResult.data]);

  const validationErrors = delve(data, ['createBooking', 'errors']);

  useEffect(() => {
    if (!error && !validationErrors?.length) { return; }
    console.log('Failed to create booking', validationErrors || error);
  }, [error, validationErrors]);

  useEffect(() => {
    const discountItem = transaction?.lines?.find(({ type }) => type === 'discount');
    if (!discountItem || discountItem?.redemption) { return; }

    createGiftRedemption({ variables: giftRedemptionVariables(discountItem) });
  }, [createGiftRedemption, transaction]);

  useEffect(() => {
    const discountItem = transaction?.lines?.find(({ type }) => type === 'discount');
    if (!discountItem || !giftRedemption || discountItem?.redemption) { return; }

    setTransaction((transaction) => {
      const index = transaction.lines.indexOf(discountItem);
      return update(transaction, {
        lines: {
          [index]: {
            contentId: { $set: giftRedemption.id },
            redemption: { $set: true },
          },
        },
      });
    });
  }, [transaction, giftRedemption]);

  const onPayClick = useCallback(
    (client, travelers) => pay(createBooking, { ...location.state, client, travelers, transaction }),
    [createBooking, location.state, transaction],
  );

  if (!location.state) { return redirectToTrip(params); }
  if (!transaction) { return redirectToTrip(params); }

  if (error || (data && !delve(data, ['createBooking', 'booking', 'transaction', 'walleeTxUrl']))) {
    return <Redirect to="/bookings/failure" />;
  }

  if (!data) {
    return (
      <StepOne
        {...location.state}
        loading={loading || giftRedemptionResult?.loading}
        onPayClick={onPayClick}
      />
    );
  }

  const iframeRef = createRef();
  return (
    <iframe
      src={data.createBooking.booking.transaction.walleeTxUrl}
      height="900"
      width="100%"
      seamless={true}
      ref={iframeRef}
      sandbox="allow-same-origin allow-top-navigation allow-scripts allow-forms"
      title="Payment"
      onLoad={event => {
        // Try to break out of iframe if same origin
        try {
          if (iframeRef.current.contentWindow.location.host === window.location.host) {
            window.location = iframeRef.current.contentWindow.location;
          }
        } catch (e) {
          // Ignore
        }
      }}
  />);
}

export default function CheckoutPage(props) {
  return (
    <Page title="Validation">
      <CheckoutPageContent {...props} />
    </Page>
  );
}
