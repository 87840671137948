import React from 'react';
import Traveler from './Traveler';

function Travelers({ trip, experience, travelers, add, remove, change }) {
  return (
    <div className="excursion-config">
      <small>Participant</small>
      <div className="user-management">
        <ul className="user-list">
          {travelers.map((t, i) => <Traveler key={t._id} trip={trip} experience={experience} traveler={t} remove={() => remove(i)} change={(t) => change(t, i)} />)}
        </ul>
        <div className="user-add">
          <button className="btn btn-secondary btn-lg btn-block" type="button" name="button" onClick={add}><i className="fa fa-plus"></i></button>
        </div>
      </div>
    </div>
  );
}

export default Travelers;
