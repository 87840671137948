import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ApolloProvider } from '@apollo/react-hooks';
import './App.scss';
import Content from './components/Pages';
import Footer from './components/App/Footer';
import Navigation from './components/App/Navigation';
import Header from './components/Header';
import ApolloClient from './core/graphql/client';
import { CoreProvider } from './core/context';

function App() {
  return (
    <Router>
      <ApolloProvider client={ApolloClient}>
        <CoreProvider>
          <HelmetProvider>
            <Helmet>
              <title>MBC</title>
            </Helmet>
            <main>
              <Navigation />
              <Header />
              <Content />
            </main>
            <Footer />
          </HelmetProvider>
        </CoreProvider>
      </ApolloProvider>
    </Router>
  );
}

export default App;
