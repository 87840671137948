import moment from 'moment';

export const DATE_FORMAT = 'DD.MM.YYYY';

export function formatTimestamp(string) {
  return moment(string).format('DD.MM.YYYY - HH:mm')
}

export function formatDate(string) {
  return moment(string).format(DATE_FORMAT)
}

export function yesterdayISO8601() {
  return moment().subtract(1, 'day').format('YYYY-MM-DD');
}
