import moment from 'moment';
import { filterByInclusion } from './utils';

export const FAILED_WALLEE_STATES = ['VOIDED', 'FAILED', 'DECLINE'];

export function formattedDate(trip) {
  const date = moment(trip.date);
  return date.format('dddd LL');
}

function bookedSeats(trip) {
  return (trip.bookings || [])
    .filter(booking => !booking.transaction || !FAILED_WALLEE_STATES.includes(booking.transaction.walleeState))
    .reduce((n, booking) => n + booking.travelersCount, 0);
}

export function tripById(excursion, tripId) {
  if (!excursion || !excursion.trips) { return null; }
  return excursion.trips.find(({ id }) => id === tripId);
}

export function availableSeats(trip) {
  if (!trip) { return null; }

  return trip.maxSeats - bookedSeats(trip);
}

export function tripExperience(trip, excursion) {
  return excursion.experiences?.find(e => e.id === trip.experience.id) || excursion.experiences?.[0];
}

export function filterTrips(trips, filters = {}) {
  if (!trips) { return []; }
  let filtered = trips;

  if (!filters.skipTimeFilter) {
    filtered = filtered.filter(t => t.date && moment(t.date).isAfter(moment().endOf('day')));
  }

  if (filters.selectedMobilities && filters.selectedMobilities.length) {
    filtered = filterByInclusion(filtered, t => t?.acceptedMobilities?.map(m => m.id) || [], filters.selectedMobilities);
  }

  if (!filters.skipAvailabilityFilter) {
    filtered = filtered.filter(t => !t.maxSeats || availableSeats(t) >= (filters.travelersCount || 1));
  }

  if (filters.startDate) {
    const startMoment = moment(filters.startDate);
    filtered = filtered.filter(t => moment(t.date).isSameOrAfter(startMoment, 'day'));
  }
  if (filters.endDate && filters.endDate > filters.startDate) {
    const endMoment = moment(filters.endDate);
    filtered = filtered.filter(t => moment(t.date).isSameOrBefore(endMoment, 'day'));
  }
  return filtered;
}
