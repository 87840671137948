import React from 'react';
import delve from 'dlv';
import { loader } from 'graphql.macro';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Carousel from './Carousel';

export const QUERY = loader('../../core/graphql/queries/landing_pages.gql');
export const VISIBILITY_QUERY = gql`{ carouselHidden @client }`;

function Home() {
  const { data: visibilityData } = useQuery(VISIBILITY_QUERY);
  const { data } = useQuery(QUERY);

  return (
    <Carousel
      slides={delve(data, ['landingPages', 0, 'carousel', 'slides'])}
      hidden={delve(visibilityData, ['carouselHidden'])}
  />);
}

export default Home;
