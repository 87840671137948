import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { loader } from 'graphql.macro';
import update from 'react-addons-update';
import Trips from './Trips';
import Loader from '../../../App/Loader';
import { stripHTML, minBy } from '../../../../core/utils';
import { filterTrips } from '../../../../core/trip';
import { yesterdayISO8601 } from '../../../../admin/core/date';

export const QUERY = loader('./excursion.gql');

function firstSlideUrl(excursion) {
  return minBy(excursion?.carousel?.slides, (slide) => slide.order)?.image;
}

export function needsLoader(excursion) {
  return !excursion.experiences;
}

function Content({ excursion, filters, onExcursionUpdated, isGift, children }) {
  const [dateActive, setDateActive] = useState(false);

  const updateTrip = useCallback((trip) => {
    const index = excursion.trips.findIndex(({ id }) => id === trip.id);
    onExcursionUpdated(update(excursion, {
      trips: { [index]: { $set: { ...excursion.trips[index], ...trip } } },
    }));
  }, [excursion, onExcursionUpdated]);

  const soldOut = filterTrips(excursion.trips, filters).length === 0;

  return (
    <div className="col-sm-6">
      <div className={`card mb-4 shadow-sm excursion-item ${dateActive ? 'dates-is-active' : ''} ${soldOut ? 'item-soldout' : ''}`}>
        <Link to={{ pathname: `/excursions/${excursion.id}`, state: { excursion, isGift } }} className="exc-image" >
          <div className="card-img-top" style={{ backgroundImage: `url(${firstSlideUrl(excursion)})` }}>
            <div className="card-icon"><i className="fa fa-eye"></i></div>
          </div>
          {soldOut ? (
            <div className="soldout-label">
              <span>Plus Disponible</span>
            </div>
          ) : null}
        </Link>
        <div className="card-body">
          <div className="exc-desc">
            <p className="card-text crop-text-3">{stripHTML(excursion.description)}</p>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="exc-actions">
              <Link to={{ pathname: `/excursions/${excursion.id}/experiences`, state: { excursion, isGift } }}><i className="fa fa-utensils"></i></Link>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className="btn-show-dates" tabIndex="0" role="button" onClick={() => setDateActive(true)}><i className="fa fa-calendar"></i></a>
            </div>
          </div>
        </div>
        <Trips
          trips={excursion.trips}
          excursion={excursion}
          filters={filters}
          onTripUpdated={updateTrip}
          isGift={isGift}
          onClose={() => setDateActive(false)}
        />
      </div>
      {children}
    </div>
  );
}

function Excursion({ excursion, filters, onExcursionUpdated }) {
  const [visible, setVisible] = useState(false);
  const shouldDisplay = !needsLoader(excursion);

  useEffect(() => {
    if (shouldDisplay === visible) { return; }
    const timer = setTimeout(() => setVisible(shouldDisplay), 200);
    return () => clearTimeout(timer);
  }, [shouldDisplay, setVisible, visible]);

  const variables = {
    id: excursion.id,
    date: yesterdayISO8601(),
  };
  const isGift = window.location?.href?.includes('gifts/vouchers');
  const loader = needsLoader(excursion) ? <Loader resultPath="excursion" variables={variables} query={QUERY} onLoad={onExcursionUpdated} /> : null;
  if (!visible && loader) { return loader; }
  if (!visible) { return null; }

  return (
    <Content excursion={excursion} filters={filters} onExcursionUpdated={onExcursionUpdated} isGift={isGift} >
      {loader}
    </Content>
  );
}

export default Excursion;
