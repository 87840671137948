import React from 'react';

const $ = window.$;

export default function Tooltip({ title, children }) {
  return (
    <div data-toggle="tooltip" data-placement="top" title={title} ref={(el) => $(el).tooltip()}>
      {children}
    </div>
  );
}
