import React, { useEffect, useMemo, useState } from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import delve from 'dlv';
import Page from '../Page';
import Loading from '../../App/Loading';
import ErrorMessage from '../../App/ErrorMessage';
import Experience from './Experience';
import { yesterdayISO8601 } from '../../../admin/core/date';

export const QUERY = loader('./experiences.gql');
// export const TRIPS_QUERY = loader('./trips.gql');

function Content({ excursion, onExcursionUpdated }) {
  if (!excursion) { return null; }
  return (
    <div className="card-columns">
      {excursion.experiences.map(experience => <Experience key={experience.id} experience={experience} excursion={excursion} onExcursionUpdated={onExcursionUpdated} />)}
    </div>
  );
}

export default function ExperiencePage({ match }) {
  const id = useMemo(() => parseInt(match.params.excursionId, 10), [match.params.excursionId]);

  const { loading, error, data } = useQuery(QUERY, { variables: { id, date: yesterdayISO8601() } });
  const [excursion, setExcursion] = useState(null);

  useEffect(() => {
    if (data?.excursion) { setExcursion(data.excursion); }
  }, [data, setExcursion]);

  return (
    <Page title={delve(data, ['excursion', 'name'])}>
      <section className="py-5 experiences-block">
        <div className="container">
          <Loading loading={loading} />
          <ErrorMessage message="Failed to load experience" active={!!error} />
          <Content excursion={excursion} onExcursionUpdated={setExcursion} />
        </div>
      </section>
    </Page>
  );
}
