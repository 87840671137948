import React, { useEffect, useState } from 'react';
import Card from '../../../App/Card';
import Loading from '../Loading';
import CustomerDetails from './CustomerDetails';
import Details from './Details';
import Transaction from './Transaction';

function downloadTicket(el) {
  if (!el) { return; }
  el.click();
}

export default function Success({ uuid }) {
  const [giftVoucher, setGiftVoucher] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`/api/gifts/vouchers/${uuid}`)
      .then((res) => res.json())
      .then(({ giftVoucher }) => setGiftVoucher(giftVoucher))
      .finally(() => setLoading(false))
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, [uuid]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="success-block">
      <Card>
        <div className="success-msg">
          <i className="fa fa-check"></i>
          <h5>Le paiement a été effectué avec succès</h5>
        </div>
      </Card>

      <CustomerDetails client={giftVoucher.client} />
      <Details giftVoucher={giftVoucher} />
      <Transaction transaction={giftVoucher.transaction} />

      <div className="download-ticket">
        <a rel="noopener noreferrer" href={giftVoucher.pdf.url} className="btn btn-primary btn-lg btn-block" ref={downloadTicket}>Télécharger le PDF</a>
      </div>
    </div>
  );
}
