import React, { useCallback, useEffect, useState } from 'react';
import Loading from './Loading';

export default function RefreshGiftVoucher({ id, onCompleted }) {
  const [retryTime, setRetryTime] = useState(0);

  const refreshGiftVoucher = useCallback((id) => {
    fetch(`/api/gifts/vouchers/${id}/refresh`, { method: 'POST' })
      .then((res) => res.json())
      .then(({ giftVoucher }) => {
        const { transaction: { walleeState: state } } = giftVoucher;
        if (state !== 'FULFILL' && state !== 'FAILED') { return setRetryTime(10); }

        onCompleted(state);
      })
      .catch((err) => console.error(err));
  }, [onCompleted]);

  useEffect(() => {
    if (!id) { return; }

    refreshGiftVoucher(id);
  }, [id, refreshGiftVoucher]);

  useEffect(() => {
    if (retryTime <= 0) { return; }
    const timer = setTimeout(() => refreshGiftVoucher(id), retryTime * 1000);
    return () => clearTimeout(timer);
  }, [retryTime, id, refreshGiftVoucher]);

  return <Loading />;
}
