import uuid from 'uuid/v1';
import { STATE_ACTIVE } from '../typeflow';

export function giftProductVariables(cents) {
  const name = uuid();
  return {
    giftProduct: {
      _meta: { name, state: STATE_ACTIVE },
      price: {
        title: 'Gift Voucher',
        kind: 'product',
        cents: Math.ceil(cents),
      },
    },
  };
}
