import React, { useCallback } from 'react';
import update from 'react-addons-update';
import FieldBoundTextField from '../../../admin/components/App/Form/FieldBoundTextField';

function TravelerNameForm({ traveler, index, setTraveler }) {
  return (
    <div className="d-block my-3">
      <h6>Voyageur {index + 1}</h6>
      <div className="row">
        <div className="col-md-6 mb-3">
          <FieldBoundTextField id={`traveler${index}FirstName`} label="Prénom" field="firstName" value={traveler} change={setTraveler} optional />

        </div>
        <div className="col-md-6 mb-3">
          <FieldBoundTextField id={`traveler${index}LastName`} label="Nom" field="lastName" value={traveler} change={setTraveler} optional />
        </div>
      </div>
    </div>
  );
}

export default function TravelerNamesForm({ travelers, setTravelers }) {
  const setTraveler = useCallback((t, i) => setTravelers(update(travelers, { $splice: [[i, 1, t]] })), [travelers, setTravelers]);

  return (
    <>
      <h5 className="mb-3">Nom des voyageurs (optionnel)</h5>
      {travelers.map((traveler, index) => < TravelerNameForm key={traveler._id} traveler={traveler} index={index} setTraveler={(traveler) => setTraveler(traveler, index)} />)}
    </>
  );
}
