import React from 'react';
import { formattedDate } from '../../../../core/trip';
import Card from '../../../App/Card';

export default function Details({ giftVoucher }) {
  return (
    <Card title="Details">
      <div className="row">
        <div className="col-md-4">
          <div className="form-group">
            <label>Code</label>
            <h6 className="">{giftVoucher.code}</h6>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label>Date d'achat</label>
            <h6 className="">{formattedDate(giftVoucher.createdAt)}</h6>
          </div>
        </div>
      </div>
    </Card>
  );
}
