import React, { useCallback } from 'react';

function CarouselContent({ title, subtitle, color }) {
  if (!title && !subtitle) { return null; }
  return (
    <div className="carousel-caption d-none d-md-block" style={{ color: color || 'white' }}>
      {title ? (<h3 className="display-4" dangerouslySetInnerHTML={{ __html: title }} />) : null}
      {subtitle ? (<p className="lead">{subtitle}</p>) : null}
    </div>
  );
}

function CarouselBadge({ badge }) {
  if (!badge) { return null; }
  return (
    <div className="excursion-price">
      <div className="container">
        <div className="price-badge">
          <small className="mb-0">{badge.title}</small>
          <h1>{badge.subtitle}</h1>
        </div>
      </div>
    </div>
  );
}

function CarouselArrows({ slides }) {
  if (slides.length === 1) { return null; }
  return (
    <>
      <a className="carousel-control-prev" href="#headerCarousel" role="button" data-slide="prev">
        <span className="cc-prev-icon" aria-hidden="true">
          <i className="fa fa-arrow-left"></i>
        </span>
      </a>
      <a className="carousel-control-next" href="#headerCarousel" role="button" data-slide="next">
        <span className="cc-next-icon" aria-hidden="true">
          <i className="fa fa-arrow-right"></i>
        </span>
      </a>
    </>
  );
}

function CarouselIndicators({ slides }) {
  return (
    <ol className={`carousel-indicators ${slides.length <= 1 ? 'hide' : ''}`}>
      {slides.map((slide, index) => <li key={slide.id} data-target="#headerCarousel" data-slide-to={index} className={`${index === 0 ? 'active' : ''}`}></li>)}
    </ol>
  );
}

const DEFAULT_SLIDES = [{
  id: -1,
  image: 'https://source.unsplash.com/loAfOVk1eNc/1920x1080', // TODO: Add a default slide
}];

function Carousel({ slides, badge, hidden }) {
  const displayableSlides = (slides || DEFAULT_SLIDES).concat().sort((s1, s2) => s1.order - s2.order);
  const play = useCallback((elem) => {
    if (!elem || hidden) { return; }
    window.$(elem).carousel();
  }, [hidden])

  return (
    <header>
      {hidden && <div className="carousel-padding" style={{ marginTop: 60 }} />}
      <div id="headerCarousel" className={`mbc-carousel carousel slide ${hidden ? 'hide' : ''}`} data-ride="carousel" ref={play}>
        <CarouselIndicators slides={displayableSlides} />
        <div className="carousel-inner" role="listbox">
          {displayableSlides.map((slide, index) => (
            <div key={slide.id} className={`carousel-item ${index === 0 ? 'active' : ''}`} style={{ backgroundImage: `url(${slide.image})` }}>
              <CarouselContent {...slide} />
            </div>
          ))}
        </div>
        <CarouselArrows slides={displayableSlides} />
      </div>
      <CarouselBadge badge={badge} />
    </header>
  );
}

export default Carousel;
