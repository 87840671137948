import React from 'react';
import moment from 'moment';
import Trip from '../../../App/Trip';
import { filterTrips } from '../../../../core/trip';
import { orderBy } from '../../../../core/utils';

export default function Trips({ trips, excursion, filters, isGift, onClose, onTripUpdated }) {
  const filtered = filterTrips(trips, { ...filters, skipAvailabilityFilter: true });
  const ordered = orderBy(filtered, (trip) => moment(trip.date));

  return (
    <div className="card-dates">
      <div className="card-date-header">
        <h2>Dates Disponibles</h2>
        <span tabIndex="0" role="button" onClick={onClose} className="btn-close-dates"><i className="fa fa-times"></i></span>
      </div>
      <div className="card-date-body">
        <div className="booking-dates">
          <ul>
            {ordered.map(trip => (
              <Trip
                onTripUpdated={onTripUpdated}
                key={trip.id}
                excursion={excursion}
                trip={trip}
                isGift={isGift}
                travelersCount={(filters || { travelersCount: 1 }).travelersCount}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
