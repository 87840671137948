import React from 'react';
import Card from '../../App/Card';

export default function Loading() {
  return (
    <Card>
      <div className="success-msg text-center"><i className="fa fa-spinner fa-spin fa-2x" /></div>
      <br />
      <div className="success-msg text-center">En attente de confirmation de paiement…</div>
    </Card>
  );
}
