import { useCallback, useState } from 'react';
import uuid from 'uuid/v1';
import { prepareClient } from '../booking';
import { STATE_ACTIVE } from '../typeflow';
import { getUrl } from '../utils';

export function giftVoucherVariables({ client, productId, type, code, metadata }) {
  const name = uuid();
  return {
    giftVoucher: {
      _meta: { name, state: STATE_ACTIVE },
      client: prepareClient(client, name),
      transaction: {
        successUrl: getUrl('gifts/vouchers/success'),
        failureUrl: getUrl('gifts/vouchers/failure'),
        lines: [{ quantity: 1, contentId: productId }],
      },
      type: type.toUpperCase(),
      code,
      metadata: JSON.stringify(metadata ?? {})
    },
  };
}

export function payGiftVoucher(createGiftVoucher, { productId, client, type, code, metadata }) {
  createGiftVoucher({
    variables: giftVoucherVariables({ productId, client, type, code, metadata }),
  })
}

export function useGeneratedGiftVoucherCode() {
  const [code, setCode] = useState(null);
  const [loading, setLoading] = useState(false);
  const generateCode = useCallback(async () => {
    setLoading(true);
    const res = await fetch('/api/gifts/vouchers/generate', { method: 'GET' });
    const { code } = await res.json();
    setCode(code);
    setLoading(false);
  }, []);

  return { code, generateCode, loading };
}

export function voucherAmountCents(voucher) {
  const txTotalCents = voucher?.transaction?.totalCents;
  if (txTotalCents) { return txTotalCents; }
  try {
    const metadata = JSON.parse(voucher?.metadata);
    return metadata?.totalAmountCents;
  } catch {
    return null;
  }
}

export function isVoucherValid(voucher) {
  if (!voucher) { return false; }
  if (!voucherAmountCents(voucher)) { return false; }

  // Gift vouchers without transaction are always valid (created by admin)
  if (!voucher.transaction) { return true; }

  // Valid only if transaction is FULFILL
  return voucher.transaction.walleeState === 'FULFILL';
}
