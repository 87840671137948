import React from 'react';

export function intersection(arrA, arrB) {
  return arrA.filter(x => arrB.includes(x));
}

export function filterByInclusion(array, valuesCallback, filterValues) {
  return array.filter(e => intersection(valuesCallback(e), filterValues).length);
}

export function withProps(Component, props) {
  return function (matchProps) {
    return <Component {...props} {...matchProps} />
  }
}

export function minBy(array, callback) {
  return array.reduce((min, el) => callback(min) <= callback(el) ? min : el, array[0]);
}

export function maxBy(array, callback) {
  return array.reduce((min, el) => callback(min) > callback(el) ? min : el, array[0]);
}

export function capitalize(s) {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function stripHTML(html) {
  const $ = window.$;
  return $(`<div>${html}</div>`).text();
}

export function orderBy(array, predicate) {
  return array?.concat()?.sort((a, b) => predicate(a) - predicate(b));
}

export function arrayToMap(array, idField, valueField) {
  return array?.reduce((map, object) => {
    map[idField ? object[idField] : object] = valueField ? object[valueField] : object;
    return map;
  }, {}) ?? {};
}

export function getUrl(path) {
  const url = new URL(window.location.href);
  url.pathname = path;
  return url.toString();
}
