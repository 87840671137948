import React from 'react';
import Page from './Page';

export default function TnC() {
  return (
    <Page title="Conditions Générales de Vente">
      <section className="py-5">
        <div className="container">
          <div className="font-weight-light">
            <h3>CONDITIONS GÉNÉRALES DE VENTE</h3>
            <p className="lead">Conditions générales de vente relatives à l’achat de places pour le Train des saveurs, le Brunch des champs, les Train du Vigneron, le Train du fromager. Le train Bières et Burger et les sorties privées des Transports de la région Morges Bière Cossonay SA.</p>
            <h5>01 - Réservation</h5>
            <p>Les réservations écrites, téléphoniques, ou par internet impliquent votre adhésion aux conditions générales et obligations sanitaires ou sécuritaires en cours au moment de la sortie. Le paiement doit être effectué lors de la réservation et la réservation est valable après paiement du montant. Pour les sorties privées, le programme définitif doit être communiqué au minimum 10 jours à l’avance avec l’horaire précis de départ, le lieu de rendez-vous, le nombre exact de passagers, le déroulement détaillé du circuit et des horaires, les points particuliers, les noms et coordonnées des responsables, et d’une manière générale tout élément nécessaire à la bonne mise en œuvre du transport.</p>
            <h5>02 - Inscription</h5>
            <p>Lorsque le client inscrit d'autres participants au voyage, il répond de leurs obligations contractuelles (en particulier le paiement du prix du voyage) à l'égale des siennes et engage sa responsabilité ou celle de son entreprise.</p>
            <h5>03 - Prestation</h5>
            <p>Nos prestations découlent de la description générale figurant sur le site Internet et détaillées dans la confirmation ou pour les sorties privées, selon l'accord par écrit ou par mail. Les souhaits particuliers ne deviennent un élément du contrat que s'ils ont été confirmés par écrit et sans réserve de notre part.</p>
            <h5>04 - Annulation</h5>
            <p>Jusqu'à 15 jours calendaires avant le voyage, les billets sont remboursables à 100 %. Jusqu’à 7 jours calendaires avant le voyage, le remboursement sera effectué à 80 %. Passé ce délai, aucun remboursement ne sera effectué, sauf sur présentation d’un certificat médical attestant l’incapacité de participer à la sortie, le remboursement peut être effectué uniquement pour la personne concernée.</p>
            <h5>05 - Annulation de voyage de la part des MBC</h5>
            <p>Les MBC se réservent le droit d’annuler dans le cas de nouvelles restrictions liées à la pandémie ou lors d’affluence insuffisante. Si tel est le cas, les clients seront contactés en début de semaine pour le week-end à venir et l’événement sera reporté à une date ultérieure. Dans le cas où aucune date ne convient aux clients, ces derniers seront intégralement remboursés. Si le voyage ne pouvait avoir lieu pour des raisons de force majeure, le montant payé sera intégralement remboursé. Toute indemnité de dédommagement étant exclue.</p>
            <h5>06 - Nettoyage et déprédations</h5>
            <p>Un nettoyage normal du véhicule est compris dans le prix. Toutefois nous nous réservons le droit d'exiger des frais de réparations ou de remplacement d'éventuels dégâts dans le véhicule ainsi que le nettoyage de chewinggums et des liquides renversés dans le véhicule. Il est strictement interdit de fumer dans les véhicules.</p>
            <h5>07 – Vol</h5>
            <p>La responsabilité des MBC n'est pas engagée en cas de vol d'effet personnel, que ce soit à l'intérieur ou à l'extérieur du véhicule durant l'ensemble du transport.</p>
            <h5>08 – Animaux</h5>
            <p>La présence d'animaux dans les voitures avec une offre de restauration est défendue. Cette disposition ne s'applique pas aux chiens guide d'aveugles et aux chiens d'utilité.</p>
            <small>Janvier 2022, Transports de la région Morges Bière Cossonay SA</small>
          </div>
        </div>
      </section>
    </Page>
  );
}
