import React from 'react';
import Card from './Card';

export default function ErrorMessage({ message, active }) {
  if (!message || !active) { return null; }
  return (
    <div className="row">
      <div className="col-md-12 order-md-1">
        <Card>
          {message}
        </Card>
      </div>
    </div>
  );
}
