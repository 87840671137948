import delve from 'dlv';
import uuid from 'uuid/v1';

export function availableMenus(traveler, experience) {
  return experience.menus.filter(menu => menu.travelerKind === traveler.kind.toUpperCase());
}

export function menu(traveler, experiences) {
  return (Array.isArray(experiences) ? experiences : [experiences])
    .flatMap(e => availableMenus(traveler, e))
    .find(m => m.id === delve(traveler, ['menuIds', 0]));
}

export function menuOptions(traveler, menu) {
  if (!menu) { return []; }
  return menu.options.filter(mo => traveler.menuOptionIds.includes(mo.id));
}

export function availableExperienceOptions(traveler, experience) {
  if (!experience || !experience.options) { return []; }
  return experience.options.filter(eo => eo.travelerKinds.includes(traveler.kind.toUpperCase()));
}

export function experienceOptions(traveler, experience) {
  return availableExperienceOptions(traveler, experience)
    .filter(eo => traveler.experienceOptionIds.includes(eo.id));
}

export function travelerName(traveler) {
  return `${traveler.firstName || ''} ${traveler.lastName || ''}`;
}

export function flattenTravelers(travelers) {
  return travelers.flatMap(
    ({ count, _id, ...traveler }) => Array(count).fill(0).map((_, i) => ({ ...traveler, count: 1, _id: `${_id} ${i}` }))
  );
}

export const TRAVELER_KINDS = {
  adult: 'Adulte',
  child: 'Enfant 4 - 12 ans',
  baby: 'Enfant 0 - 3 ans',
};

export const DEFAULT_TRAVELER = () => ({
  _id: uuid(),
  kind: 'adult',
  firstName: '',
  lastName: '',
  mobilityIds: [],
  menuIds: [],
  menuOptionIds: [],
  experienceOptionIds: [],
  count: 1
});
