import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import update from 'react-addons-update';
import Page from '../Page';
import Excursions from './Excursions';
import ExcursionsList from './ExcursionsList';
import Filters from './Filters';

export const QUERY = loader('./excursions.gql');

export const DEFAULT_FILTERS = {
  selectedActivities: [],
  selectedFoods: [],
  selectedMobilities: [],
  travelersCount: 1,
  startDate: null,
  endDate: null,
};

function Content({ excursions, updateExcursion }) {
  const [filters, setFilters] = useState(DEFAULT_FILTERS);

  if (!excursions) { return null; }

  return (
    <div className="row">
      <div className="col-md-4">
        <Filters filters={filters} change={(key, value) => setFilters(update(filters, { [key]: { $set: value } }))} />
      </div>
      <div className="col-md-8">
        <Excursions excursions={excursions} filters={filters} onExcursionUpdated={updateExcursion} />
      </div>
    </div>
  );
}

function Home() {
  return (
    <Page title="Home">
      <section className="py-5 excursions-block">
        <div className="container">
          <ExcursionsList Content={Content} />
        </div>
      </section>
    </Page>
  );
}

export default Home;
