import React, { useEffect } from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import delve from 'dlv';
import Loading from '../Loading';
import Card from '../../../App/Card';
import CustomerDetails from './CustomerDetails';
import Excursion from './Excursion';
import Travelers from './Travelers';
import Transaction from './Transaction';
import Remarks from './Remarks';

export const SUCCESSFUL_BOOKING_BY_UUID_QUERY = loader('./queries/booking_by_uuid.gql');
export const TRIP_QUERY = loader('./queries/trip.gql');
export const EXCURSION_QUERY = loader('./queries/excursion.gql');

function downloadTicket(el) {
  if (!el) { return; }
  el.click();
}

export default function Success({ uuid }) {
  const [loadExcursion, { loading: loadingExcursion, data: excursionData }] = useLazyQuery(EXCURSION_QUERY);
  const [loadTrip, { loading: loadingTrip, data: tripData }] = useLazyQuery(TRIP_QUERY);
  const { loading: loadingBooking, data: bookingData } = useQuery(SUCCESSFUL_BOOKING_BY_UUID_QUERY, {
    variables: { uuid },
    onCompleted: data => loadTrip({ variables: { id: data.bookingByUUID.tripIds[0] } }),
  });

  useEffect(() => {
    if (!delve(tripData, 'trip')) { return; }
    loadExcursion({ variables: { id: tripData.trip.excursionIds[0] } });
  }, [tripData, loadingTrip, loadExcursion]);

  if (loadingBooking || loadingExcursion || loadingTrip || !delve(tripData, 'trip') || !delve(excursionData, 'excursion') || !delve(bookingData, 'bookingByUUID')) {
    return <Loading />;
  }

  return (
    <div className="success-block">
      <Card>
        <div className="success-msg">
          <i className="fa fa-check"></i>
          <h5>Le paiement a été effectué avec succès</h5>
        </div>
      </Card>

      <CustomerDetails client={bookingData.bookingByUUID.client} />
      <Excursion excursion={excursionData.excursion} trip={tripData.trip} />
      <Travelers travelers={bookingData.bookingByUUID.travelers} excursion={excursionData.excursion} trip={tripData.trip} />
      <Remarks booking={bookingData.bookingByUUID} />
      <Transaction transaction={bookingData.bookingByUUID.transaction} excursion={excursionData.excursion} />

      <div className="download-ticket">
        <a rel="noopener noreferrer" href={bookingData.bookingByUUID.ticket.url} className="btn btn-primary btn-lg btn-block" ref={downloadTicket}>Télécharger le PDF</a>
      </div>
    </div>
  );
}
