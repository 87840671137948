import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import Loading from './Loading';

export const REFRESH_BOOKING_MUTATION = loader('./refresh_booking.gql');

export default function RefreshBooking({ id, onCompleted }) {
  const [retryTime, setRetryTime] = useState(0);
  const [refreshBooking] = useMutation(REFRESH_BOOKING_MUTATION, {
    onCompleted: (result) => {
      if (!result || !result.refreshBooking) {
        return setRetryTime(10);
      }
      const { walleeState } = result.refreshBooking.booking.transaction;
      if (walleeState !== 'FULFILL' && walleeState !== 'FAILED') { return setRetryTime(10); }
      onCompleted(walleeState);
    }
  });

  useEffect(() => {
    if (!id) { return; }
    refreshBooking({ variables: { id } });
  }, [id, refreshBooking]);

  useEffect(() => {
    if (retryTime <= 0) { return; }
    const timer = setTimeout(() => refreshBooking({ variables: { id } }), retryTime * 1000);
    return () => clearTimeout(timer);
  }, [retryTime, id, refreshBooking]);

  return <Loading />;
}
