import ApolloClient from 'apollo-boost';

export function createClient(token) {
  let uri = process.env.REACT_APP_CMS_GRAPHQL_URL;
  if (token) { uri += `?token=${token}`; }
  return new ApolloClient({
    uri,
    resolvers: {},
  });
}

const client = createClient(process.env.REACT_APP_CMS_TOKEN);

export default client;
