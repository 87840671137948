import React from 'react';
import { Route, Switch } from 'react-router-dom';
import HomePage from './HomePage';
import EnterprisePage from './EnterprisePage';
import TnCPage from './TnCPage';
import ExcursionPage from './ExcursionPage';
import TripPage from './TripPage';
import CheckoutPage from './CheckoutPage';
import ExperiencesPage from './ExperiencesPage';
import PrivacyPage from './PrivacyPage';
import BookingStatusPage from './BookingStatusPage';
import ContactPage from './ContactPage';
import GiftVouchersPage from './GiftVouchersPage';
import GiftVouchersCheckoutPage from './GiftVouchersCheckoutPage';
import GiftVoucherStatusPage from './GiftVouchersStatusPage';

function Routes() {
  return (
    <div className="content">
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/enterprise" component={EnterprisePage} />
        <Route path="/privacy" component={PrivacyPage} />
        <Route path="/cgv" component={TnCPage} />
        <Route path="/contact" component={ContactPage} />
        <Route path="/excursions/:excursionId/trips/:tripId/checkout" component={CheckoutPage} />
        <Route path="/excursions/:excursionId/trips/:id" component={TripPage} />
        <Route path="/excursions/:excursionId/experiences" component={ExperiencesPage} />
        <Route path="/excursions/:id" component={ExcursionPage} />
        <Route path="/bookings/:status" component={BookingStatusPage} />
        <Route path="/gifts/vouchers/checkout" component={GiftVouchersCheckoutPage} />
        <Route path="/gifts/vouchers/:status" component={GiftVoucherStatusPage} />
        <Route path="/gifts/vouchers" component={GiftVouchersPage} />
      </Switch>
    </div>
  );
}

export default Routes;
