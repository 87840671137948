import React from 'react';
import update from 'react-addons-update';
import { TRAVELER_KINDS } from '../../../../core/traveler';

export default function Kind({ experience, traveler, change }) {
  const kinds = Object.keys(TRAVELER_KINDS).filter((kind) => experience?.menus?.find((menu) => menu.travelerKind === kind.toUpperCase()));
  return (
    <div className="col-md-4">
      <div className="form-group">
        <label htmlFor="">Type</label>
        <select className="traveler-kind" name="" defaultValue={traveler.kind} onChange={(e) => change(update(traveler, { kind: { $set: e.target.value } }))}>
          {kinds.map((kind) => <option key={kind} value={kind}>{TRAVELER_KINDS[kind]}</option>)}
        </select>
      </div>
    </div>
  );
}
