import { getUrl } from './utils';

function groupBy(xs, key) {
  return xs.reduce(function (rv, x) {
    const array = Array.isArray(x[key]) ? x[key] : [x[key]];
    array.forEach(groupKey => (rv[groupKey] = rv[groupKey] || []).push(x));
    return rv;
  }, {});
}

function lineForMenu(menuId, count, experience) {
  const menu = experience.menus.find(m => m.id === menuId);
  if (!menu) { return null; }
  return {
    ...lineForContent(menu, count),
    pdfUrl: menu.pdf,
  };
}

function lineForMenuOption(id, count, experience) {
  const menuOption = experience.menus.flatMap(m => m.options || []).find(mo => mo.id === id);
  if (!menuOption) { return null; }
  return lineForContent(menuOption, count);
}

function lineForExperienceOption(id, count, experience) {
  const experienceOption = experience.options.find(mo => mo.id === id);
  if (!experienceOption) { return null; }
  return lineForContent(experienceOption, count);
}

function lineForContent(option, count) {
  return {
    title: option.name,
    subtitle: `${count} personne${ count > 1 ? 's' : ''}`,
    contentId: option.id,
    quantity: count,
    type: 'product',
    total: option.transactionDetail.cents * count / 100,
  };
}

export function travelersCount(array) {
  return array.reduce((sum, t) => sum + (t.count || 1), 0);
}

export default class Transaction {
  constructor(experience, travelers, options = {}) {
    if (!experience || !travelers) { return; }

    const menuGroup = Object.entries(groupBy(travelers, 'menuIds')) || [];
    const menuOptionsGroup = Object.entries(groupBy(travelers, 'menuOptionIds')) || [];
    const experienceOptionsGroup = Object.entries(groupBy(travelers, 'experienceOptionIds')) || [];
    this.lines = [
      ...experienceOptionsGroup.map(([experienceOptionId, group]) => lineForExperienceOption(experienceOptionId, travelersCount(group), experience)),
      ...menuGroup.map(([menuId, group]) => lineForMenu(menuId, travelersCount(group), experience)),
      ...menuOptionsGroup.map(([menuOptionId, group]) => lineForMenuOption(menuOptionId, travelersCount(group), experience)),
    ].filter(e => !!e)

    this.lines = [...this.lines, ...this.giftVoucherLines(options.giftVoucher)];
  }

  static fromHash(transaction) {
    const tx = new Transaction();
    tx.lines = transaction?.lines;
    return tx;
  }

  prepare() {
    if (!this.lines) { return undefined; }

    return {
      successUrl: getUrl('bookings/success'),
      failureUrl: getUrl('bookings/failure'),
      lines: this.prepareLines(),
    };
  }

  prepareLines() {
    return this.lines.map(line => ({
      quantity: line.quantity,
      contentId: line.contentId,
    }));
  }

  totalInCents() {
    if (!this.lines) { return 0; }
    return this.lines.reduce((sum, { total }) => sum + total * 100, 0);
  }

  giftVoucherLines(voucher) {
    if (!voucher?.availableCents) { return []; }

    return [{
      title: 'Bon Cadeau',
      subtitle: voucher.code,
      contentId: voucher.id,
      quantity: 1,
      type: 'discount',
      total: - Math.min(this.totalInCents(), voucher.availableCents) / 100,
    }];
  }
}
