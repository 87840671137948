import React from 'react';
import Card from '../../../App/Card';
import { formattedDate } from '../../../../core/trip';

export default function Excursion({ excursion, trip }) {
  return (
    <Card title="Excursion">
      <div className="row">
        <div className="col-md-4">
          <div className="form-group">
            <label>Nom</label>
            <h6 className="">{excursion.name}</h6>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label>Date</label>
            <h6 className="">{formattedDate(trip)}</h6>
          </div>
        </div>
      </div>
    </Card>
  );
}
