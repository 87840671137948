import React from 'react';
import moment from 'moment';
import { filterTrips } from '../../core/trip';
import Trip from './Trip';
import { orderBy } from '../../core/utils';

export default function Trips({ trips, excursion, hideTitle, subtitle, onTripUpdated, isGift }) {
  const filtered = filterTrips(trips, { skipAvailabilityFilter: true });
  if (!filtered.length) { return null; }

  const ordered = orderBy(filtered, (trip) => moment(trip.date));

  return (
    <>
      {!hideTitle && (
        <h5 className="d-flex justify-content-between align-items-center mb-3">
          <span className="">Dates Disponibles</span>
        </h5>
      )}
      <div className="booking-dates">
        {subtitle && <h6>{subtitle}</h6>}
        <ul>
          {ordered.map(trip => <Trip key={trip.id} trip={trip} excursion={excursion} onTripUpdated={onTripUpdated} isGift={isGift} />)}
        </ul>
      </div>
    </>
  );
}
