import { useQuery } from "@apollo/react-hooks";
import { loader } from "graphql.macro";

export const MOBILITIES_QUERY = loader('../graphql/queries/mobilities.gql');
export const FOOD_KINDS_QUERY = loader('../graphql/queries/food_kinds.gql');

export function useMobilities() {
  const { data } = useQuery(MOBILITIES_QUERY);
  return data?.mobilities;
}

export function useFoodKinds() {
  const { data } = useQuery(FOOD_KINDS_QUERY);
  return data?.foodKinds;
}
