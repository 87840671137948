import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { withProps } from '../../core/utils';
import Excursion from './Excursion';
import Home from './Home';
import Image from './Image';

function Header() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/enterprise" component={withProps(Image, { title: "Entreprise", path: "https://source.unsplash.com/O8dKwgPSSUk/1920x1080" })} />
      <Route path="/privacy" component={withProps(Image, { title: "Politique de Confidentialité", path: "https://source.unsplash.com/9AdeEdYB2yk/1920x1080" })} />
      <Route path="/cgv" component={withProps(Image, { title: "Conditions Générales de Vente", path: "https://source.unsplash.com/9AdeEdYB2yk/1920x1080" })} />
      <Route path="/contact" component={withProps(Image, { title: "Contact", path: "https://source.unsplash.com/hjwKMkehBco/1920x1080" })} />
      <Route path="/excursions/:id/trips/:tripId" component={Excursion} />
      <Route path="/excursions/:id" component={Excursion} />
      <Route path="/gifts/vouchers/checkout" component={withProps(Image, { title: "Bon Cadeau", path: "https://source.unsplash.com/9AdeEdYB2yk/1920x1080" })} exact={true} />
      <Route path="/gifts/vouchers" component={withProps(Image, { title: "Bon Cadeau", path: "https://source.unsplash.com/9AdeEdYB2yk/1920x1080" })} exact={true} />
    </Switch>
  );
}

export default Header;
