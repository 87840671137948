import React from 'react';
import { NavLink, Link } from "react-router-dom";
import { ReactComponent as Logo } from '../../assets/svg/logo-mbc.svg'

export function NavigationMenu() { // eslint-disable-line no-unused-vars
  return (
    <>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarResponsive">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <NavLink exact={true} to="/" className="nav-link">Home</NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/enterprise" className="nav-link">Entreprise</NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/contact" className="nav-link">Contact</NavLink>
          </li>
        </ul>
      </div>
    </>
  );
}

function Navigation() {
  return (
    <nav className="mbc-navbar navbar navbar-expand-lg navbar-dark fixed-top">
      <div className="container">
        <Link to="/" className="navbar-brand">
          <Logo />
        </Link>
      </div>
    </nav>
  );
}

export default Navigation;
