import React, { useMemo } from 'react';
import { formatMoney } from '../../../../admin/core/money';
import Card from '../../../App/Card';

export default function Transaction({ transaction }) {
  const discountLine = useMemo(
    () => transaction?.lines?.find(({ detail: { kind } }) => kind === 'discount'),
    [transaction],
  );
  const totalCents = useMemo(
    () => transaction?.lines?.filter(({ detail: { kind } }) => kind !== 'discount').reduce((a, { detail: { cents: b } }) => a + b, 0),
    [transaction],
  );

  return (
    <Card title="Total">
      <div className="row">
        <div className="col-md-4">
          <div className="form-group">
            <label>Prix</label>
            <h4 className="">{formatMoney(totalCents, 2, 'CHF', '0.00')}</h4>
          </div>
        </div>
        {discountLine ? (
          <>
            <div className="col-md-4">
              <div className="form-group">
                <label>Bon Cadeau</label>
                <h4 className="">{formatMoney(discountLine.detail.cents, 2, 'CHF', '0.00')}</h4>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Prix</label>
                <h4 className="">{formatMoney(transaction.totalCents, 2, 'CHF', '0.00')}</h4>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </Card>
  );
}
