import React, { useState, createRef, useMemo, useCallback } from 'react';
import { DEFAULT_CLIENT, validateClient } from '../../../core/client';
import { flattenTravelers } from '../../../core/traveler';
import Booking from '../../Booking';
import ClientForm from './ClientForm';
import TravelerNamesForm from './TravelerNamesForm';

function validateTravelers(_travelers) {
  return true;
}

export default function StepOne({ onPayClick, loading, transaction, experience, travelers: initialTravelers, trip }) {
  const formRef = createRef();
  const [client, setClient] = useState(DEFAULT_CLIENT);
  const [travelers, setTravelers] = useState(flattenTravelers(initialTravelers));

  const valid = useMemo(() => validateClient(client) && validateTravelers(travelers), [client, travelers]);
  const handlePayClick = useCallback(() => {
    if (!valid || loading) { return; }
    const form = formRef.current;
    if (typeof form.reportValidity === 'function' && !form.reportValidity()) { return; }
    onPayClick(client, travelers);
  }, [client, travelers, formRef, loading, onPayClick, valid]);

  return (
    <section className="py-5 page-payment">
      <div className="container">
        <div className="row">
          <div className="col-md-4 order-md-2 mb-4">
            <h5 className="d-flex justify-content-between align-items-center mb-3">
              <span className="">Panier</span>
              <span className="badge badge-danger badge-pill">{transaction.lines.length}</span>
            </h5>
            <Booking transaction={transaction} experience={experience} trip={trip} >
              <button
                id="checkout-pay"
                className={`btn btn-primary btn-lg btn-block ${!valid || loading ? 'disabled' : ''}`}
                type="submit"
                onClick={handlePayClick}
              >
                {loading ? 'En attente…' : 'Payer'}
              </button>
            </Booking>
          </div>
          <div className="col-md-8 order-md-1">
            <h5 className="mb-3">Adresse de facturation</h5>
            <form className="needs-validation" ref={formRef}>
              <ClientForm client={client} onChange={setClient} formRef={formRef} />
              <TravelerNamesForm travelers={travelers} setTravelers={setTravelers} />
            </form>
          </div>
        </div>
      </div>
    </section >
  );
}
