import React from 'react';
import update from 'react-addons-update';
import { arrayToMap } from '../../../../core/utils';

function ButtonOption({ value, selected, change }) {
  return (
    <button className={`filter-item ${selected ? 'active' : ''}`} type="button" onClick={() => change(!selected)}>{value}</button>
  );
}

function MobilityOption({ value, selected, change }) {
  return (
    <div className="control-icon-checkbox">
      <label>
        <input className="real-toggle" type="checkbox" defaultChecked={selected} onChange={() => change(!selected)} />
        <div className="fake-toggle"><i className={value}></i></div>
      </label>
    </div>
  );
}

function SelectableTags({ options, selected, change, componentClass }) {
  return (
    <>
      {
        Object.entries(options).map(([key, value]) => React.createElement(componentClass, {
          key,
          value,
          selected: selected.includes(key),
          change: (active) => change(update(selected, active ? { $push: [key] } : { $splice: [[selected.indexOf(key), 1]] }))
        }))
      }
    </>
  );
}

export function MobilityFilters({ options, ...props }) {
  return (
    <div className="form-group m-0">
      <div className="control-icon-checkbox-group">
        <SelectableTags componentClass={MobilityOption} {...props} options={arrayToMap(options, 'id', 'icon')} />
      </div>
    </div>
  );
}

export function TextFilters(props) {
  return (
    <div className="filter-buttons">
      <SelectableTags componentClass={ButtonOption} {...props} />
    </div>
  );
}

export function FoodKindFilters({ options, ...props }) {
  return <TextFilters options={arrayToMap(options, 'id', 'name')} {...props} />
}
