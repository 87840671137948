import React from 'react';
import Card from '../../../App/Card';

export default function CustomerDetails({ client: { firstName, lastName, address, zipCode, canton, city } }) {
  return (
    <Card title="Détails Client">
      <div className="row">
        <div className="col-md-4">
          <div className="form-group">
            <label>Nom</label>
            <h6 className="">{firstName} {lastName}</h6>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="form-group">
            <label>Adresse</label>
            <h6 className="">{address}<br />{zipCode}-{canton} {city}</h6>
          </div>
        </div>
      </div>
    </Card>
  );
}
