import React from 'react';
import Page from './Page';

export default function Privacy() {
  return (
    <Page title="Privacy Policy">
      <section className="py-5">
        <div className="container">
          <div className="py-5 font-weight-light">
            <h5>PROTECTION DES DONNÉES</h5>
            <p><b>Nous accordons la plus grande importance à la protection de vos données personnelles, c’est pourquoi nous tenons à vous informer sur la manière dont les Transports de la région Morges Bière Cossonay SA (MBC) les traitent.</b></p>
            <p>Cette déclaration de protection des données décrit en détail comment vos données personnelles sont traitées, lorsque vous utilisez les services offerts par le webshop des Transports de la région Morges Bière Cossonay SA (MBC).</p>
            <p>En utilisant les services du webshop des Transports de la région Morges Bière Cossonay SA (MBC), vous acceptez cette déclaration de protection des données et notre traitement des données personnelles dans le respect du droit suisse sur la protection des données, notamment par la Loi fédérale sur la protection des données (LPD).</p>

            <h5>Sécurité</h5>
            <p>Toutes les dispositions techniques et organisationnelles requises et appropriées sont prises pour protéger vos données personnelles de tout accès non autorisé et de toute utilisation abusive. Ces mesures seront adaptées de façon appropriée à l’état actuel de la technique.</p>

            <h5>Les cookies</h5>
            <p>Nous n’utilisons pas les cookies pour notre webshop</p>

            <h5>Analyse Web</h5>
            <p>Nous n’utilisons pas d’outils d’analyse pour notre webshop.</p>

            <h5>Fichiers journaux du serveur</h5>
            <p>Nos systèmes ne collectent et n’enregistrent pas les informations lorsque que vous naviguez sur notre webshop dans ce que l’on appelle les fichiers journaux.</p>

            <h5>Formulaire de contact</h5>
            <p>Dans certaines sections de notre webshop, nous vous demandons et récoltons des données personnelles telles que nom, prénom, numéro de téléphone, fonction, entreprise et/ou l’adresse e-mail par le biais de formulaires.</p>
            <p>Ces données seront enregistrées dans le but de traiter votre requête et au déroulement de relations d’affaires, notamment pour des newsletters à but informatif.</p>
            <p>Nous ne transmettons pas vos données personnelles à des tiers sans votre accord. Vous avez la possibilité de vous désinscrire de notre base de données, en utilisant le lien de description dans nos courriels ou par notre formulaire de contact. Les données seront supprimées avec effet immédiat de nos bases de données.</p>

            <h5>Paiements (e-commerce)</h5>
            <p>Les données relatives aux cartes bancaires ne nous sont pas transmises et sont traitées par un système sécurisé, spécialisée dans le traitement de données e-commerce.</p>

            <h5>Modifications de la déclaration relative à la protection des données</h5>
            <p>Nous pouvons apporter à tous moments des modifications à la présente déclaration sur la protection des données. Toutes modifications fondamentales seront communiquées en temps voulu et de façon bien visible sur notre site web.</p>
          </div>
        </div>
      </section>
    </Page>
  );
}
