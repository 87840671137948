import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Page from '../Page';

export const QUERY = loader('./excursions.gql');

export default function GiftVouchersPage() {
  const [type, setType] = useState('custom');
  const [amount, setAmount] = useState('');
  const [quantity, setQuantity] = useState(1);

  const { data } = useQuery(QUERY);

  const excursionOptions = useMemo(() => {
    if (!data?.excursions?.length) { return []; }

    return data.excursions.map((excursion) => {
      if (!excursion.giftVoucherAmount) { return null; }

      return {
        id: excursion.id,
        label: `Offrez l'excursion: ${excursion.name}`,
        excursionName: excursion.name,
        amount: excursion.giftVoucherAmount,
      };
    }).filter(Boolean);
  }, [data]);

  const selectedExcursion = useMemo(() => {
    if (!type.startsWith('excursion')) { return false; }
    const [, id] = type.split('-');
    return excursionOptions.find((option) => option.id === id);
  }, [type, excursionOptions]);

  const checkoutState = useMemo(() => {
    const totalAmount = selectedExcursion ? (selectedExcursion.amount * (parseInt(quantity, 10) ?? 1)) : amount;
    return {
      ...(selectedExcursion || {}),
      totalAmountCents: Math.ceil(totalAmount * 100),
      type: type.split('-')[0].toUpperCase()
    };
  }, [amount, type, selectedExcursion, quantity]);

  const isValid = useMemo(() => checkoutState?.totalAmountCents > 0, [checkoutState]);

  return (
    <Page title="Bon Cadeau">
      <section className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2 mb-4">

              <div className="form-group">
                <label htmlFor="voucher-type">Sélectionner un type de bon cadeau</label>
                <select className="voucher-type" name="" value={type} onChange={(e) => setType(e.target.value)}>
                  <option value="custom">Choisir un montant libre</option>
                  {excursionOptions.map((option) => (
                    <option key={option.id} value={`excursion-${option.id}`}>{option.label}</option>
                  ))}
                </select>
              </div>

              {type === 'custom' ? (
                <div className="form-group mb-3">
                  <label htmlFor="voucher-amount">Montant (CHF)</label>
                  <input id="voucher-amount" type="number" className="form-control" name="amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                </div>
              ) : null}

              {selectedExcursion ? (
                <>
                  <div className="form-group mb-3">
                    <label htmlFor="voucher-amount">Montant (CHF)</label>
                    <input id="voucher-amount" type="number" className="form-control" name="amount" value={selectedExcursion.amount} disabled />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="voucher-quantity">Quantité</label>
                    <input id="voucher-quantity" type="number" className="form-control" name="amount" value={quantity} step={1} onChange={(e) => setQuantity(e.target.value)} />
                  </div>
                </>
              ) : null}

              <Link
                id="btn-checkout"
                to={{
                  pathname: '/gifts/vouchers/checkout',
                  state: checkoutState,
                }}
                className={`btn btn-primary btn-lg btn-block mt-5 ${isValid ? '' : 'disabled'}`}
              >
                Procéder
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Page>
  );
}
