import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import React, { createRef, useEffect, useMemo, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { payGiftVoucher } from '../../../core/gifts/vouchers';
import Page from '../Page';
import CheckoutPageForm from './CheckoutPageForm';

export const MUTATION = loader('./giftVoucher.gql');

function redirectToGiftVouchers() {
  return <Redirect to="/gifts/vouchers" />;
}

function CheckoutPageContent({ location, history }) {
  const [createGiftVoucher, { loading, data, error }] = useMutation(MUTATION);
  const validationErrors = useMemo(() => data?.createGiftVoucher?.errors, [data]);
  const [paymentUrl, setPaymentUrl] = useState(null);

  // Log validation errors
  useEffect(() => {
    if (!error && !validationErrors?.length) { return; }
    console.log('Failed to create gift voucher', validationErrors || error);
  }, [error, validationErrors]);

  // Fetch payment page URL
  useEffect(() => {
    const id = data?.createGiftVoucher?.giftVoucher?.id;
    if (!id) { return; }

    fetch(`/api/gifts/vouchers/${id}/payment`)
      .then((res) => res.ok ? res.json() : null)
      .then(({ url }) => setPaymentUrl(url))
      .catch(() => history.push('/gifts/vouchers/failure'));
  }, [data, history]);

  const type = useMemo(() => {
    if (location.state?.type) { return location.state.type; }
    if (location.state?.experience && location.state?.transaction && location.state?.trip) { return 'excursion'; }
    return null;
  }, [location]);
  if (!type) { return redirectToGiftVouchers(); }

  if (error || (data && !data?.createGiftVoucher?.giftVoucher?.id)) {
    return <Redirect to="/gifts/vouchers/failure" />;
  }

  if (!paymentUrl) {
    return (
      <CheckoutPageForm
        {...location.state}
        loading={loading}
        onPayClick={({ client, productId, code }) => payGiftVoucher(createGiftVoucher, { type, client, productId, code, metadata: location.state })}
      />
    );
  }

  const iframeRef = createRef();
  return (
    <iframe
      src={paymentUrl}
      height="900"
      width="100%"
      seamless={true}
      ref={iframeRef}
      sandbox="allow-same-origin allow-top-navigation allow-scripts allow-forms"
      title="Payment"
      onLoad={event => {
        // Try to break out of iframe if same origin
        try {
          if (iframeRef.current.contentWindow.location.host === window.location.host) {
            window.location = iframeRef.current.contentWindow.location;
          }
        } catch (e) {
          // Ignore
        }
      }}
    />);
}

export default function GiftVouchersCheckoutPage(props) {
  return (
    <Page title="Validation">
      <CheckoutPageContent {...props} />
    </Page>
  );
}
