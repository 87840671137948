import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import update from 'react-addons-update';
import ErrorMessage from '../../App/ErrorMessage';
import Loading from '../../App/Loading';

export const QUERY = loader('./excursions.gql');

function ExcursionsListContent({ excursions: initialExcursions, Content }) {
  const [excursions, setExcursions] = useState(initialExcursions);

  useEffect(() => {
    setExcursions(initialExcursions);
  }, [setExcursions, initialExcursions]);

  const updateExcursion = useCallback((excursion) => {
    const index = excursions.findIndex(({ id }) => id === excursion.id);
    setExcursions(update(excursions, {
      [index]: { $set: { ...excursions[index], ...excursion } },
    }));
  }, [excursions, setExcursions]);

  return <Content excursions={excursions} updateExcursion={updateExcursion} />;
}

export default function ExcursionsList({ Content }) {
  const client = useApolloClient();
  const { loading, error, data } = useQuery(QUERY, { returnPartialData: true });
  const excursions = useMemo(() => data?.excursions, [data]);
  const loadingVisible = useMemo(() => loading && !(excursions && excursions.length), [loading, excursions]);

  useEffect(() => {
    client.writeData({ data: { carouselHidden: loadingVisible } })
  }, [client, loadingVisible]);

  return (
    <>
      <Loading loading={loadingVisible} />
      <ErrorMessage message="Erreur lors du chargement des données" active={!!error} />
      <ExcursionsListContent excursions={excursions} Content={Content} />
    </>
  );
}
