import delve from 'dlv';
import { useCallback } from 'react';
import update from 'react-addons-update';
import { availableMenus, menu } from '../traveler';

export default function useTravelerMenu({ experience, traveler, change }) {
  const menus = availableMenus(traveler, experience);
  const currentMenu = menu(traveler, experience);
  if (!currentMenu) {
    if (delve(traveler, ['menuIds', 'length']) && !menus.length) {
      change(update(traveler, { menuIds: { $set: [] } }));
    } else if (menus.length) {
      change(update(traveler, { menuIds: { $set: [delve(menus, [0, 'id'])] } }));
    }
  }

  const setMenuId = useCallback((menuId) => change(update(traveler, { menuIds: { $set: [menuId] } })), [change, traveler]);

  return { menus, currentMenu, setMenuId };
}
