import uuid from 'uuid/v1';

export function giftRedemptionVariables(line) {
  const name = uuid();

  return {
    giftRedemption: {
      _meta: {
        state: 'ACTIVE',
        name,
      },
      voucherId: line.contentId,
      amount: {
        title: `${line.title} - ${line.subtitle}`,
        kind: 'discount',
        cents: Math.floor(line.total * 100),
      },
    },
  };
}
