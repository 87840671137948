import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { availableSeats } from '../../core/trip';
import { capitalize } from '../../core/utils';
import Loader from './Loader';
const $ = window.$;

function initCircleProgress(el) {
  $(el).circleProgress({
    size: 30,
    thickness: 6,
    fill: '#009B3E',
    emptyFill: 'rgba(0, 0, 0, .2)'
  });
}

export const QUERY = loader('./trip.gql');

function TripLinkOrSoldOut({ excursion, trip, soldOut, isGift, children }) {
  if (soldOut) {
    return (
      <div className="soldout-wrapper">
        {children}
        <div className="date-soldout-label">
          <span>Plus Disponible</span>
        </div>
      </div>
    );
  }

  return (
    <Link to={{
      pathname: `/excursions/${excursion.id}/trips/${trip.id}`,
      state: { trip, excursion, isGift }
    }}>
      {children}
    </Link>
  );
}

export default function Trip({ trip, excursion, onTripUpdated, travelersCount, isGift }) {
  if (trip.id && !trip.experience) {
    return <Loader resultPath="trip" variables={{ id: trip.id }} query={QUERY} onLoad={onTripUpdated} />;
  }

  const date = moment(trip.date);
  const available = availableSeats(trip);
  const remaining =  available / trip.maxSeats;
  const soldOut = available < travelersCount;

  return (
    <li className={soldOut ? 'item-soldout' : null}>
      <TripLinkOrSoldOut excursion={excursion} trip={trip} soldOut={soldOut} isGift={isGift}>
        <span><small>{trip.experience.name}</small><br />{capitalize(date.format('dddd LL'))}</span>
        <div className="exc-remaining">
          <div className="circle-p" data-value={remaining} ref={initCircleProgress}></div>
          <div className="circle-percentage">{available}/{trip.maxSeats}</div>
        </div>
      </TripLinkOrSoldOut>
    </li>
  );
}

Trip.defaultProps = { travelersCount: 1 };
