import React, { Fragment } from 'react';
import Card from '../../../App/Card';
import { menu, menuOptions, experienceOptions, travelerName, TRAVELER_KINDS } from '../../../../core/traveler';
import { tripExperience } from '../../../../core/trip';
import { useCoreContext } from '../../../../core/context';

function Menu({ traveler, excursion }) {
  const travelerMenu = menu(traveler, excursion.experiences);
  if (!travelerMenu) { return null; }
  return (
    <div className="col-md-4">
      <div className="form-group">
        <label>Plat</label>
        <h6 className="">{travelerMenu.name}</h6>
      </div>
    </div>
  );
}

function Accessories({ traveler }) {
  const { mobilities } = useCoreContext();
  if (!traveler.mobilityIds?.length || !mobilities?.length) { return null; }
  return (
    <div className="col-md-4">
      <div className="form-group">
        <label>Accessoires</label>
        <h6 className="">{traveler.mobilityIds.map(id => mobilities.find(m => m.id === id)?.name).join(', ')}</h6>
      </div>
    </div>
  );
}

function Options({ traveler, excursion, trip }) {
  const options = [
    ...menuOptions(traveler, menu(traveler, excursion.experiences)),
    ...experienceOptions(traveler, tripExperience(trip, excursion)),
  ];
  if (!options.length) { return null; }
  return (
    <div className="col-md-6">
      <div className="form-group">
        <label>Options</label>
        {options.map(option => <h6 key={option.id} className="success-traveler-option">{option.name}</h6>)}
      </div>
    </div>
  );
}

function Traveler({ traveler, excursion, trip }) {
  return (
    <div className="traveler-data-item">
      <div className="row">
        <div className="col-md-3">
          <div className="form-group">
            <label>Type</label>
            <h6 className="">{TRAVELER_KINDS[traveler?.kind?.toLowerCase()]}</h6>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>Nom</label>
            <h6 className="">{travelerName(traveler)}</h6>
          </div>
        </div>
        <Menu traveler={traveler} excursion={excursion} />
        <Accessories traveler={traveler} />
      </div>
      <div className="row">
        <Options traveler={traveler} excursion={excursion} trip={trip} />
      </div>
    </div>
  );
}

function TravelersTitle({ count }) {
  return (
    <>
      Voyageurs{'\u00a0'}
      <small>({count})</small>
    </>
  );
}
export default function Travelers({ travelers, excursion, trip }) {
  return (
    <Card title={<TravelersTitle count={travelers.length} />}>
      {travelers.map((traveler, index) => (
        <Fragment key={traveler.id}>
          <Traveler traveler={traveler} excursion={excursion} trip={trip} />
          {index < travelers.length - 1 ? <hr /> : null}
        </Fragment>
      ))}
    </Card>
  );
}
