export const DEFAULT_CLIENT = {
  firstName: '',
  lastName: '',
  email: '',
  address: '',
  canton: '',
  city: '',
  zipCode: '',
  phone: '',
};

export function validateClient(client) {
  return client.firstName && client.lastName && client.email && client.address
    && client.canton && client.city && client.zipCode && client.phone;
}
