import uuid from 'uuid/v1';
import Transaction from './transaction';
import { STATE_ACTIVE } from './typeflow';

export function prepareTravelers(travelers, bookingName) {
  let travelerCount = 0;
  return travelers.flatMap((traveler) =>
    Array(traveler.count).fill(0).map(() => ({
      _meta: { name: `${bookingName} traveler ${++travelerCount}`, state: STATE_ACTIVE },
      kind: traveler.kind.toUpperCase(),
      mobilityIds: traveler.mobilityIds,
      menuIds: traveler.menuIds,
      menuOptionIds: traveler.menuOptionIds,
      experienceOptionIds: traveler.experienceOptionIds,
      firstName: traveler.firstName,
      lastName: traveler.lastName,
    })),
  );
}

export function prepareClient(client, bookingName) {
  return {
    _meta: { name: `${bookingName} client`, state: STATE_ACTIVE },
    ...client
  };
}

export function bookingVariables({ transaction, trip, travelers, client, remarks, totalInCents, giftRedemptionId }) {
  const name = uuid();
  const redemptionLine = transaction?.lines?.find(({ type }) => type === 'discount');
  return {
    booking: {
      _meta: { name, state: STATE_ACTIVE },
      travelers: prepareTravelers(travelers, name),
      client: prepareClient(client, name),
      transaction: Transaction.fromHash(transaction).prepare(),
      tripIds: [trip.id],
      remarks,
      totalInCents,
      giftRedemptionId: giftRedemptionId || (redemptionLine?.contentId ?? null),
    },
  };
}

export function pay(createBooking, { transaction, trip, travelers, client, remarks }) {
  createBooking({
    variables: bookingVariables({ transaction, trip, travelers, client, remarks }),
  })
}

export const CANTONS = {
  VD: 'VD',
  GE: 'GE',
  VS: 'VS',
  ZH: 'ZH',
  BE: 'BE',
  LU: 'LU',
  UR: 'UR',
  SZ: 'SZ',
  OW: 'OW',
  NW: 'NW',
  GL: 'GL',
  ZG: 'ZG',
  FR: 'FR',
  SO: 'SO',
  BS: 'BS',
  BL: 'BL',
  SH: 'SH',
  AR: 'AR',
  AI: 'AI',
  SG: 'SG',
  GR: 'GR',
  AG: 'AG',
  TG: 'TG',
  TI: 'TI',
  NE: 'NE',
  JU: 'JU',
};
