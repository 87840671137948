import React from 'react';
import Page from './Page';

export default function ContactPage() {
  return (
    <Page title="Contact">
      <section className="py-5">
        <div className="container">

          <div className="row">
            <div className="col-md-8 mb-5">
              <h5>Say Something!</h5>
              <div className="py-3">
                <p className="font-weight-light">Lorem ipsum dolor sit amet, consectetur adipisicing elit. A deserunt neque tempore recusandae animi soluta quasi? Asperiores rem dolore eaque vel.</p>
              </div>
              <div className="py-3">
                <form className="needs-validation">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="firstName">Name</label>
                      <input type="text" className="form-control" id="firstName" placeholder="" required="" />
                      <div className="invalid-feedback">
                        Valid first name is required.
                    </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="lastName">Email</label>
                      <input type="text" className="form-control" id="lastName" placeholder="" required="" />
                      <div className="invalid-feedback">
                        Valid last name is required.
                    </div>
                    </div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="exampleFormControlTextarea1">Message</label>
                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                  </div>

                  <button className="btn btn-primary btn-lg btn-block" type="submit">Send Message</button>
                </form>
              </div>
            </div>
            <div className="col-md-4 mb-5">
              <h5>Contacts</h5>
              <div className="py-3">
                <address>
                  <strong>MBC Morges</strong>
                  <br />3481 Melrose Place
                <br />Beverly Hills, CA 90210
                <br />
                </address>
                <address>
                  <span title="Phone">T:</span>
                  (123) 456-7890
                <br />
                  <span title="Email">E:</span>
                  <a href="mailto:#">name@example.com</a>
                </address>
              </div>

              <div className="py-3">
                <address>
                  <strong>Start Bootstrap</strong>
                  <br />3481 Melrose Place
                <br />Beverly Hills, CA 90210
                <br />
                </address>
                <address>
                  <span title="Phone">T:</span>
                  (123) 456-7890
                <br />
                  <span title="Email">E:</span>
                  <a href="mailto:#">name@example.com</a>
                </address>
              </div>

            </div>
          </div>

        </div>
      </section>
    </Page>
  );
}
