import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import React, { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import { formatMoney } from '../../../admin/core/money';
import { DEFAULT_CLIENT, validateClient } from '../../../core/client';
import { giftProductVariables } from '../../../core/gifts/products';
import { formattedDate } from '../../../core/trip';
import BookingLine from '../../Booking/Line';
import ClientForm from '../CheckoutPage/ClientForm';
import Booking from '../../Booking';
import Transaction from '../../../core/transaction';
import { useGeneratedGiftVoucherCode } from '../../../core/gifts/vouchers';

export const MUTATION = loader('./giftProduct.gql');

function Button({ productLoading, error, onPayClick, valid, loading, createProduct }) {
  const { code, generateCode } = useGeneratedGiftVoucherCode();
  useEffect(() => { generateCode(); }, [generateCode]);

  const handlePlayClick = useCallback(() => onPayClick(code), [onPayClick, code]);
  return (
    <>
      {productLoading || !error ? (
        <button
          id="checkout-pay"
          className={`btn btn-primary btn-lg btn-block ${!valid || loading || productLoading || !code ? 'disabled' : ''}`}
          type="submit"
          onClick={handlePlayClick}
        >
          {loading ? 'En attente…' : 'Payer'}
        </button>
      ) : null}
      {!productLoading && error ? (
        <>
          <button
            id="checkout-reload"
            className="btn btn-primary btn-lg btn-block"
            type="button"
            onClick={createProduct}
          >
            Retry
          </button>
          <p className="text-danger">Impossible de charger les données</p>
        </>
      ) : null}
    </>
  );
}
export default function CheckoutPageForm({ onPayClick, totalAmountCents, experience, transaction, trip, loading }) {
  const [createGiftProduct, { loading: productLoading, data, error }] = useMutation(MUTATION);

  const formRef = createRef();
  const [client, setClient] = useState(DEFAULT_CLIENT);

  const productId = useMemo(() => data?.createGiftProduct?.giftProduct?.id, [data]);
  const valid = useMemo(() => validateClient(client), [client]);

  const handlePayClick = useCallback((code) => {
    if (!valid || loading || productLoading) { return; }
    const form = formRef.current;
    if (typeof form.reportValidity === 'function' && !form.reportValidity()) { return; }
    onPayClick({ client, productId, code });
  }, [client, formRef, loading, onPayClick, valid, productLoading, productId]);

  const totalInCents = useMemo(() => totalAmountCents ? totalAmountCents : Transaction.fromHash(transaction)?.totalInCents(), [totalAmountCents, transaction]);
  const createProduct = useCallback(() => {
    const variables = giftProductVariables(totalInCents);
    createGiftProduct({ variables });
  }, [createGiftProduct, totalInCents]);
  useEffect(() => { createProduct(); }, [createProduct]);

  return (
    <section className="py-5 page-payment">
      <div className="container">
        <div className="row">
          <div className="col-md-4 order-md-2 mb-4">
            <h5 className="d-flex justify-content-between align-items-center mb-3">
              <span className="">Panier</span>
              <span className="badge badge-danger badge-pill">{transaction?.lines.length ?? 1}</span>
            </h5>
            {experience && transaction && trip ? (
              <Booking transaction={transaction} experience={experience} trip={trip} >
                <Button
                  productLoading={productLoading}
                  error={error}
                  onPayClick={handlePayClick}
                  valid={valid}
                  loading={loading}
                  createProduct={createProduct}
                />
              </Booking>
            ) : (
              <>
                <ul className="list-group mb-3">
                  <li className="list-group-item d-flex justify-content-between bg-dark">
                    <div className="text-white">
                      <h6 className="my-0">Bon Cadeau</h6>
                      <small>{formattedDate(new Date())}</small>
                    </div>
                  </li>
                  <BookingLine key="line" line={{ title: 'Bon Cadeau', subtitle: '', total: totalAmountCents / 100 }} />
                  <li className="list-group-item d-flex justify-content-between list-item-transaction-total">
                    <span>Total (CHF)</span>
                    <h2>{formatMoney(totalAmountCents, 2, '')}</h2>
                  </li>
                </ul>
                <Button
                    productLoading={productLoading}
                    error={error}
                    onPayClick={handlePayClick}
                    valid={valid}
                    loading={loading}
                    createProduct={createProduct}
                  />
              </>
            )}
          </div>
          <div className="col-md-8 order-md-1">
            <h5 className="mb-3">Adresse de facturation</h5>
            <form className="needs-validation" ref={formRef}>
              <ClientForm client={client} onChange={setClient} formRef={formRef} />
            </form>
          </div>
        </div>
      </div>
    </section >
  );
}
