import React from 'react';
import BookingLine from './Line';
import { formattedDate } from '../../core/trip';
import { formatMoney } from '../../admin/core/money';

function Booking({ transaction, experience, trip, children }) {
  return (
    <>
      <ul className="list-group mb-3">
        <li className="list-group-item d-flex justify-content-between bg-dark">
          <div className="text-white">
            <h6 className="my-0">{experience.name}</h6>
            <small>{formattedDate(trip)}</small>
          </div>
        </li>
        {transaction.lines.map(line => <BookingLine key={line.contentId} line={line} />)}
        <li className="list-group-item d-flex justify-content-between list-item-transaction-total">
          <span>Total (CHF)</span>
          <h2 id="booking-total">{formatMoney(transaction.lines.map(l => l.total).reduce((a, b) => a + b, 0) * 100, 2, '')}</h2>
        </li>
      </ul>
      {children}
    </>
  );
}

export default Booking;
