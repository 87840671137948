import React from 'react';
import Menu from './Menu';
import Kind from './Kind';
import Options from './Options';
import Accessories from './Accessories';
import Count from './Count';

function Traveler({ trip, experience, traveler, remove, change }) {
  return (
    <li className="user-item">
      <div className="user-form">
        <div className="form-row">
          <Kind experience={experience} traveler={traveler} change={change} />
          <Menu experience={experience} traveler={traveler} change={change} />
          <Count traveler={traveler} change={change} />
        </div>
        <div className="form-row">
          <p>Le train historique ne dispose que de tables à quatre personnes. Par souci d&apos;optimisation, il se peut que les groupes inférieurs à quatre personnes soient appelés à partager la même table.</p>
          <Accessories trip={trip} traveler={traveler} change={change} />
          <Options experience={experience} menu={experience.menus.find(m => m.id === traveler.menuIds[0])} traveler={traveler} change={change} />
        </div>
      </div>
      <div className="user-delete">
        <button type="button" name="button" onClick={remove}><i className="fa fa-trash"></i></button>
      </div>
    </li>
  );
}

export default Traveler;
