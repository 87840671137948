import React from 'react';
import update from 'react-addons-update';

export default function Accessory({ id, icon, traveler, change }) {
  const active = traveler.mobilityIds.includes(id);
  const toggle = () => {
    const operation = active ? { mobilityIds: { $set: traveler.mobilityIds.filter(a => a !== id) } }
      : { mobilityIds: { $push: [id] } };
    change(update(traveler, operation));
  };
  return (
    <div className="control-icon-checkbox">
      <label>
        <input className="real-toggle" type="checkbox" defaultChecked={active} onChange={toggle} />
        <div className="fake-toggle"><i className={icon}></i></div>
      </label>
    </div>
  );
}
