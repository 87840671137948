import React from 'react';
import Card from "../../../App/Card";

export default function Remarks({ booking: { remarks } }) {
  if (!remarks) { return null; }
  return (
    <Card title="Remarques">
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <h6>{remarks}</h6>
          </div>
        </div>
      </div>
    </Card>
  );
}
