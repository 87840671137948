import React from 'react';
import { Link } from "react-router-dom";

function ListItem({ url, text }) {
  return (
    <li className="list-inline-item">
      <Link to={url}>{text}</Link>
    </li>
  );
}

function Footer() {
  return (
    <footer className="footer bg-light">
      <div className="container">
        <div className="row footer-info">
          <div className="col-lg-3">
            <h5>Contact vente:</h5>
            <p><a href="mailto:vente@mbc.ch">Email: vente@mbc.ch</a><br /><a href="tel:021 811 43 45">Tel: 021 811 43 45</a></p>
          </div>
          <div className="col-lg-4">
            <h5>Heures d’ouverture:</h5>
            <p>Lundi au vendredi : 08h00-12h00 et 13h00-17h00</p>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 h-100 text-center text-lg-left my-auto small">
            <ul className="list-inline mb-2">
              <ListItem url="/cgv" text="Conditions Générales " />
              <li className="list-inline-item">&sdot;</li>
              <ListItem url="/privacy" text="Politique de Confidentialité" />
            </ul>
            <p className="text-muted mb-4 mb-lg-0">&copy; MBC 2019. Tous droits réservés.</p>
          </div>
          <div className="col-lg-6 h-100 text-center text-lg-right my-auto">
            <ul className="list-inline mb-0">
              <li className="list-inline-item mr-3">
                <a href="https://www.facebook.com/mbc.transports/">
                  <i className="fab fa-2x fa-facebook  fa-fw"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
