import React, { useCallback } from 'react';
import update from 'react-addons-update';
import Trips from '../../App/Trips';

export default function Excursion({ excursion, onExcursionUpdated, isGift }) {
  const updateTrip = useCallback((trip) => {
    const index = excursion.trips.findIndex(({ id }) => id === trip.id);
    onExcursionUpdated(update(excursion, {
      trips: {
        [index]: {
          $set: { ...excursion.trips[index], ...trip },
        },
      }
    }));
  }, [onExcursionUpdated, excursion]);

  if (!excursion) { return null; }
  return (
    <div className="row">
      <div className="col-md-4 order-md-2 mb-4">
        <Trips trips={excursion.trips} excursion={excursion} onTripUpdated={updateTrip} isGift={isGift} />
      </div>

      <div className="col-md-8 order-md-1">
        <h5 className="mb-3 excursion-title">{excursion.name}</h5>
        <p dangerouslySetInnerHTML={{ __html: excursion.description }} />
      </div>
    </div>
  );
}
