import { useEffect } from 'react';
import delve from 'dlv';
import { useQuery } from '@apollo/react-hooks';

export default function Loader({ resultPath, onLoad, query, variables }) {
  const { data } = useQuery(query, { variables });
  const result = delve(data, resultPath);

  useEffect(() => {
    if (!result || !onLoad) { return; }
    const id = setTimeout(() => onLoad(result), 10);
    return () => clearTimeout(id);
  }, [onLoad, result]);

  return null;
}
